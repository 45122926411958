import React from 'react'
import { IconCarSuv,IconCar,IconAmbulance,IconWheelchair } from '@tabler/icons-react'

export default function TransportBadge({transportType,transportNote}) {
    
    return (
        getTransportBadge(transportType)
    )


    function getTransportBadge(transportType){
        if(transportType === "autonome") return <IconCar/>;
        if(transportType === "wheelchair") return <IconWheelchair/>;
        else return <IconCar/>
    }
}

import React,{useState} from 'react'
import { Container, Title,Select, Paper, Flex, UnstyledButton,Stack, Card } from '@mantine/core';
import { ChronoCard } from '../member/chronoCardModal';
import {Route,Routes,Link} from "react-router-dom";
import { Tabs,Text } from '@mantine/core';
import { useQuery } from 'react-query';
import { fetchPaymentsFromMonth,fetchAllPayments, fetchPaymentsBetween, fetchAllGrants } from '../connections/requests';
import {exportAllPayments} from "../connections/requests";
import { IconPencilPlus } from '@tabler/icons-react';
import { PaymentTable } from '../payment/PaymentTable';
import { PaymentTableAll } from '../grant/GrantTableAll';
import { PaymentTableBetween } from '../payment/PaymentTableBetween';
import { DatePicker } from '@mantine/dates';
import GrantPage from '../grant/GrantPage';


const months= [ 
{ value:'0',label:"Janvier"},
{ value: '1', label: 'Février' },
{ value: '2', label: 'Mars' },
{ value: '3', label: 'Avril' },
{ value: '4', label: 'Mai' },
{ value: '5', label: 'Juin' },
{ value: '6', label: 'Juillet' },
{ value: '7', label: 'Août' },
{ value: '8', label: 'Septembre' },
{ value: '9', label: 'Octobre' },
{ value: '10', label: 'Novembre' },
{ value: '11', label: 'Décembre' }
];

export default function PaymentPage() {
    return (
        <Container>
            <Card my={"lg"}>
                <Card.Section my={"lg"} bg={"blue"}>
                    <Title color='white' order={1} ta={"center"}>Subventions</Title>
                </Card.Section>
        <Flex gap={'md'}>

            </Flex>
        <Routes>
            <Route exact path="" element={<MainPage />} />
            <Route path=":grant" element={<GrantPage />} />
        </Routes>
        </Card>
        </Container>
    )
}
function MainPage(){
    const {data,status} = useQuery('grant',fetchAllGrants);
    if(status==='success')return(        
    <Container>
        <PaymentTableAll data={data}/>
    </Container>)
    return <div>null</div>
}

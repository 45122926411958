import React from 'react'
import { useForm } from '@mantine/form';
import { Button, Flex, TextInput } from '@mantine/core';
import { cancelPayment } from '../../connections/requests';
export default function CancelPaymentPrompt({paymentID,backOut}) {
  const form = useForm({initialValues:{_id:paymentID,cancelDescription:""}});
  return (

    <form>
        <TextInput label="Raison de l'annulation" {...form.getInputProps("cancelDescription")}/>
        <Flex mt={"lg"} gap={"lg"} justify={"center"} align={"center"}> 
          <Button onClick={()=>cancelPayment(form.values._id,form.values.cancelDescription)}>Annuler le paiement</Button>
         <Button onClick={()=> backOut()}>Retour en arrière</Button>
        </Flex>
    </form>

  )
}

import { useState, React,useEffect } from 'react';
import {
    createStyles,
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    Pagination,
    Card,
    Flex,
    Paper,
    Chip
} from '@mantine/core';
import { keys } from '@mantine/utils';
import { IconSelector, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import PaymentRow from './components/PaymentRow';


const useStyles = createStyles((theme) => ({
    th: {
        padding: '0 !important',
    },

    control: {
        width: '100%',
        padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },
    icon: {
        width: 21,
        height: 21,
        borderRadius: 21,
    },
}));

function Th({ children, reversed, sorted, onSort, justify = "apart" }) {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group position={justify}>
                    <Text weight={500} size="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon size={14} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </th>
    );
}

function filterData(data, search, filterButton) {
    const filter = filterButton;
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => { if (typeof item[key] == "string") return item[key].toLowerCase().includes(query); return null;  })
    ).filter((item) => {
        if (filter.includes("admission") && item?.type === 'admission') return true;
        if(filter.includes("renouvellement") && item?.type === "renouvellement") return true;
        if (filter.includes("don") && item?.type === 'don') return true;
        if (filter.includes('adhésion') && item?.type === 'adhésion') return true;
        if (filter.includes('activité') && item?.type === 'activité') return true;
        if (!item.type) return true;
        return false
    });
}

function sortData(data,payload) {
    const { sortBy } = payload;
    if (!sortBy) {

        return filterData(data, payload.search, payload.filterButton);
    }
    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }
            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search, payload.filterButton
    );
}

export function PaymentTable(props) {
    let data = props.data.payments;
    let sum = props.data.sum;
    let search = "";
    const [activePage, setPage] = useState(1);
    const [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
    const [filterButton, setFilterButton] = useState(["actif", "inactif","admission" ,"renouvellement", "adhésion", "don", "activité"]);
    const setSorting = (field) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        setSortedData(sortData(data, { sortBy: field, reversed, search, filterButton }));
    };
    const setFilter = (event) => {
        setFilterButton(event);
        setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search, filterButton: event }));
    };
    useEffect(()=>{
        setSortedData(data)
    },[data])

    const rows = data ? sortedData.slice((activePage-1)*15,(activePage)*15).map((row) => (
            <PaymentRow key={row._id} row={row}/>
    )):<div>Choisir un mois</div>;
    return (
        <Paper p={'lg'}>
            <ScrollArea>
            <Chip.Group multiple position='center' value={filterButton} onChange={setFilter}>
                    <Chip value='renouvellement' color="blue">Renouvellement</Chip>
                    <Chip value='don' color="green">Don</Chip>
                    <Chip value='activité' color="orange">Activité</Chip>
                    <Chip value='adhésion' color="violet">Adhésion</Chip>
                </Chip.Group>
                <Card>
                    <Flex justify={"flex-start"} align={"initial"} gap={"lg"}>
                        <Text>
                    Somme : {sum?.toFixed(2) + " $"}
                    </Text>
                    <Text>
                        Nombre de paiements : {data.length}
                    </Text>
                    </Flex>
                    </Card>
                <Table
                    horizontalSpacing="md"
                    verticalSpacing="xs"
                    sx={{ tableLayout: 'fixed', minWidth: 700 }}
                >
                    <thead>
                        <tr>
                            <Th
                        
                            
                                sorted={sortBy === 'worker'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('worker')}
                            >
                                Utilisateur
                            </Th>
                            <Th
                                sorted={sortBy === 'member'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('member')}
                            >
                            Membre
                            </Th>
                            <Th
                                sorted={sortBy === 'amount'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('amount')}
                            >
                                Montant
                            </Th>
                            <th>
                                Date
                            </th>
                            <th>
                                Type
                            </th>
                            <th>
                                Statut
                            </th>
                            <th>
                                Options
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.length > 0 ? (
                            rows
                        ) : (
                            <tr>
                                <td colSpan={3}>
                                    <Text weight={500} align="center">
                                        Vide
                                    </Text>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <Center>
                <Pagination page={activePage} onChange={setPage} total={(data.length/15+1)} />
                </Center>
            </ScrollArea>
        </Paper>
    );
}

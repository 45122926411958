import { createStyles, Select, TextInput, Radio, Button, Container,Chip, Stack, Text, Card,Switch, Grid, NumberInput, Checkbox } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import 'dayjs/locale/fr-ca';
import { useForm } from '@mantine/form';
import { createOneMember } from '../connections/requests';
import { useState } from 'react';
import InputMask from 'react-input-mask';
import { IconMail, IconPhone } from '@tabler/icons-react';
const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
    },

    input: {
        height: 'auto',
        paddingTop: 18,
    },

    label: {
        position: 'absolute',
        pointerEvents: 'none',
        fontSize: theme.fontSizes.xs,
        paddingLeft: theme.spacing.sm,
        paddingTop: theme.spacing.sm / 2,
        zIndex: 1,
    },
}));
function formatPhone(phone){
    if(phone==="") return "";
    return phone.match(/\d+/g).join("")
}

export function AddMemberForm() {
    let today = new Date(Date.now());
    const { classes } = useStyles();
    const [birthdayMode,setBirthdayMode] = useState(false);
    const form = useForm(
        { initialValues: 
        { idMembre:"",prenom: "", nom: "", email: "", phone: "",actif: true, genre: '', dateOfBirth: "",ageAnonym :false,dayOfBirth:"",monthOfBirth:"", civic: "",city:"", postal: "",dateOfRegistration: today,dateOfMembership:today, type: "",volunteer:false },
         validate:{
                email: (value) => ( (/^\S+@\S+$/.test(value)|| value==="") ? null : 'Courriel Invalide'),
                phone: (value)=> ( (/^\(\d{3}\)\s\d{3}-\d{4}/.test(value) || value==="")? null:'Telephone Invalide'),
                prenom:(value)=> ((value.length > 2) ? null: "Prenom Requis"),
                nom:(value)=> ((value.length > 2) ? null: "Nom Requis")},
         transformValues: (values) => ({
            ...values,
            phone: formatPhone(values.phone),
            ageAnonym: birthdayMode,
          }),
    });
    return (
        <form onSubmit={form.onSubmit((values) => {createOneMember(values).then(form.reset)})}>
        <Container py={35} px={50}>
            <Stack align='center'>
                <Card mb={34} w={"14rem"}  >
                    <Text align='center' size={"xl"} weight={700}>Ajouter un Membre</Text>
                </Card>
            </Stack>
            <Stack>
                <Grid >
                    <Grid.Col span={6}>
                    <TextInput label="Prénom" placeholder="" classNames={classes} {...form.getInputProps('prenom')} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                    <TextInput label="Nom" placeholder="" classNames={classes} {...form.getInputProps('nom')} />
                    </Grid.Col>
                </Grid>
                <TextInput label="Numéro Membre" classNames={classes} {...form.getInputProps('idMembre')} />
                <Radio.Group name="genre" {...form.getInputProps('genre')} label="Genre">
                    <Radio value="homme" label="Homme" />
                    <Radio value="femme" label="Femme" />
                    {/* <Radio value="nonbinaire" label="Non-Binaire / Autre" />*/}
                </Radio.Group>
                <TextInput label="Courriel" classNames={classes} {...form.getInputProps('email')} />
                <TextInput label="Adresse Civique" placeholder="" classNames={classes} {...form.getInputProps('civic')} />
                <TextInput label = "Ville" placeholder="" classNames={classes} {...form.getInputProps("city")}/>
                <TextInput label="Code Postal" placeholder="" classNames={classes} {...form.getInputProps('postal')} />
                <TextInput component={InputMask} mask="(999) 999-9999" label="Téléphone" classNames={classes} {...form.getInputProps('phone')} />
                <Select mb={"lg"}
                    style={{ marginTop: 20, zIndex: 2 }}
                    data={['Téléphone', 'Courriel', 'Poste', 'Autres']}
                    placeholder={""}
                    label="Préférence de communication"
                    {...form.getInputProps('commPref')}
                />

                <Grid>
                    <Grid.Col span={2}>
                <Switch label="Anonymat de l'âge" checked={birthdayMode} onChange={(event) => setBirthdayMode(event.currentTarget.checked)} classNames={classes}/>
                    </Grid.Col>
                    <Grid.Col span={10}>
                {birthdayMode?                 
                    <Grid >
                        <Grid.Col span={6}>
                            <NumberInput label="Jour" placeholder="" classNames={classes} {...form.getInputProps('dayOfBirth')} />
                        </Grid.Col>
                        <Grid.Col span={6}>
                        <Select
                    data={["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"]}
                    label="Mois"
                    classNames={classes}
                    {...form.getInputProps('monthOfBirth')}
                />
                    </Grid.Col>
                    </Grid> : 
                <DatePicker
                    inputFormat='DD MMMM YYYY'
                    labelFormat='DD/MMMM/YYYY'
                    locale='fr-ca'
                    label="Date de Naissance"
                    classNames={classes}
                    allowFreeInput
                    clearable={false}
                    {...form.getInputProps('dateOfBirth')}
                />

}              </Grid.Col> </Grid>
                <Select
                    required
                    style={{ marginTop: 20, zIndex: 2 }}
                    data={['Régulier', 'Sympathisant', 'Non-Membre', 'Autres']}
                    placeholder="Status membre"
                    label="Status membre"
                    classNames={classes}
                    {...form.getInputProps('type')}
                />
                    <DatePicker
                    inputFormat='DD MMMM YYYY'
                    labelFormat='DD/MMMM/YYYY'
                    locale='fr-ca'
                    label="Date d'adhésion'"
                    classNames={classes}
                    allowFreeInput
                    clearable={false}
                    {...form.getInputProps('dateOfRegistration')}
                />
                                    <DatePicker
                    inputFormat='DD MMMM YYYY'
                    labelFormat='DD/MMMM/YYYY'
                    locale='fr-ca'
                    label="Date de renouvellement"
                    classNames={classes}
                    allowFreeInput
                    clearable={false}
                    {...form.getInputProps('dateOfMembership')}
                />
                <Checkbox label="Bénévole" {...form.getInputProps("volunteer")}/>
                <Button type='submit' >Soumettre</Button>
            </Stack>
            <Chip.Group value={[]} m={"lg"}>
                <Chip>Activité Formative</Chip>
                <Chip>Activité Culturelle </Chip>
                <Chip>Activité Social</Chip>
                <Chip>Activité Plein-air </Chip>
            </Chip.Group>
        </Container >
        </form>
    );
}
import React from 'react'
import parse from "html-react-parser"
import { Paper, Text } from "@mantine/core";

export default function FollowUp({ suivi }) {
    console.log(suivi);
    return (
        <Paper shadow='sm' p="xs" withBorder>
            {parse(suivi.description)}
            <Text>{suivi.author}</Text>
        </Paper>
    )
}

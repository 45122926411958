import { createStyles, Select, TextInput,  Button, Container, Stack, Text, Card,Flex,Modal, Radio, Group } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import {DatePicker} from "@mantine/dates";
import 'dayjs/locale/fr-ca';
import { useForm } from '@mantine/form';
import { createOnePayment,searchMember, getUser, createOneGrant } from '../connections/requests';
import { useState } from 'react';
import {useQuery} from "react-query";
const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
    },

    input: {
        height: 'auto',
        paddingTop: 18,
    },

    label: {
        position: 'absolute',
        pointerEvents: 'none',
        fontSize: theme.fontSizes.xs,
        paddingLeft: theme.spacing.sm,
        paddingTop: theme.spacing.sm / 2,
        zIndex: 1,
    },
}));



export function AddGrantForm() {
    const { classes } = useStyles();
    const [search, setSearch] = useState("");
    const [debouncedSearch] = useDebouncedValue(search, 200)
    const form = useForm(
        { initialValues: 
        { title:"",provider:"", amount: 0, type: "Subvention",description:"",deadline:new Date()}
        });
    return (
        <form onSubmit={form.onSubmit((values) => {createOneGrant(values).then(form.reset)})}>

        <Container py={35} px={50}>
            <Stack align='center'>
                <Card mb={34} w={"14rem"}  >
                    <Text align='center' size={"xl"} weight={700}>Ajouter une subvention</Text>
                </Card>
            </Stack>
            <Stack>
                <Group grow align={"center"} gap={"lg"}>
                <TextInput label="Titre" classNames={classes} {...form.getInputProps("title")}/>
                <TextInput label="Partenaire"  placeholder="" classNames={classes} {...form.getInputProps('provider')} />
                </Group>
                <TextInput label="Montant" classNames={classes} {...form.getInputProps('amount')} />
                <TextInput label="Description et notes" classNames={classes} {...form.getInputProps("description")}/>
                <DatePicker
                    inputFormat='DD MMMM YYYY'
                    labelFormat='DD/MMMM/YYYY'
                    locale='fr-ca'
                    label="Date"
                    classNames={classes}
                    allowFreeInput
                    clearable={false}
                    {...form.getInputProps('deadline')}
                />
                <Button type='submit'>Soumettre</Button>
            </Stack>
        </Container >
        </form>
    );
}
import React from 'react'
import { Card, Grid, Text, UnstyledButton,Modal,Button,Radio,Container,Stack,TextInput,Flex,Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCurrencyDollar, IconTrash } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { getUser } from '../connections/requests';
import { createOnePayment,pullMemberFromEvent } from '../connections/requests';
import { useDisclosure } from '@mantine/hooks';
import { DatePicker } from '@mantine/dates';



function SetToPayment({member,event}){
    const form = useForm(
        { initialValues: 
        { member:member , worker: getUser() ,event:event, amount: 0, type: "activité",description:"",status:"waiting",timeStamp:new Date()}
        });
    return (
        <form onSubmit={form.onSubmit((values) => {createOnePayment(values).then(form.reset)})}>

        <Container py={35} px={50}>
            <Stack align='center'>
                <Card mb={34} w={"14rem"}  >
                    <Text align='center' size={"xl"} weight={700}>Ajouter un paiement</Text>
                </Card>
            </Stack>
            <Stack>
                <Flex align={"center"} gap={"lg"}>
                <TextInput disabled  placeholder=""  {...form.getInputProps('member')} />
                </Flex>

                <TextInput label="Montant"  {...form.getInputProps('amount')} />
                <TextInput label="Description et notes"  {...form.getInputProps("description")}/>
                <Select
                    required
                    disabled
                    style={{ marginTop: 20, zIndex: 2 }}
                    data={['adhésion','renouvellement', 'don', 'activité', 'autre']}
                    placeholder="..."
                    label="Type de paiement"
                    
                    {...form.getInputProps('type')}
                />
                <Radio.Group name="status"  label="Status"
                     {...form.getInputProps('status')}>
                <Radio color='orange' value="waiting" label="En Attente"/>
                <Radio color='green' value="received" label="Reçu"/>
                </Radio.Group>
                <DatePicker
                    inputFormat='DD MMMM YYYY'
                    labelFormat='DD/MMMM/YYYY'
                    locale='fr-ca'
                    label="Date"
                    allowFreeInput
                    clearable={false}
                    {...form.getInputProps('timeStamp')}
                />
                <Button type='submit'>Soumettre</Button>
            </Stack>
        </Container >
        </form>
    )
}

export default function EventMemberRow({member,event,paid}) {

    const [opened,handlers] = useDisclosure(false)
    return (
        <>
        <Modal opened={opened} onClose={()=> handlers.toggle()}>
        <SetToPayment member={member._id} event={event}/>
        </Modal>
            <Card withBorder px={"xl"}>
                <Grid justify={"space-evenly"} >
                    <Grid.Col span={8}>
                    <Text component={Link} to={`/auth/member/${member._id}`} >{`${member?.prenom}  ${member?.nom}`}</Text>
                    </Grid.Col>
                    <Grid.Col span={2}>
                    <UnstyledButton onClick={() => handlers.open()} > {paid ? <IconCurrencyDollar color='green' /> : <IconCurrencyDollar color='red' />}
                    </UnstyledButton>
                    </Grid.Col>
                    <Grid.Col span={2}>
                    <UnstyledButton onClick={() => {pullMemberFromEvent(member._id, event);}}><IconTrash color='red' /></UnstyledButton>
                    </Grid.Col>
                </Grid>
            </Card>
        </>
    )
}

import { useState } from "react";
import { createStyles, Paper, Text, ThemeIcon, Group, Modal, Grid, Button, Stack, TextInput } from '@mantine/core';
import { deletePayment, receivePayment} from "../../connections/requests";
import { IconHeartDollar, IconUserDollar } from "@tabler/icons-react";
import { useForm } from '@mantine/form';
import { modifyPayment } from "../../connections/requests";
import CancelPaymentPrompt from "./CancelPaymentPrompt";
const parse = require('html-react-parser');

const useStyles = createStyles((theme) => ({
    card: {
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        transition: 'transform 150ms ease, box-shadow 100ms ease',
        padding: theme.spacing.xl,
        paddingLeft: theme.spacing.xl * 2,

        '&:hover': {
            boxShadow: theme.shadows.md,
            transform: 'scale(1.02)',
        },

        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.green[6], theme.colors.blue[6]),
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.green[6], theme.colors.blue[6]),
        },
    }, cardClosed: {
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.red[4], theme.colors.orange[9]),
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.red[4], theme.colors.orange[9]),
        }

    }, cardProg: {
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.orange[4], theme.colors.yellow[9]),
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.orange[4], theme.colors.yellow[9]),
        }
    }

    , buttons: {
        position: "absolute",
        right: '1rem',
        top: '1rem',
    }
}));
const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
function getIcon(type){
    switch (type) {
        case "don":
            return <IconHeartDollar/>
        case "admission":
            return <IconUserDollar/>
    
        default:
            break;
    }
}
function getPaymentStatus(status){
    switch (status) {
        case "canceled":
            return "Annulé"
        case "received":
            return "Reçu"
        case "waiting":
            return "En attente"
        default:
            return "erreur"
    }
}
export function PaymentCard(_props) {
    const props = _props.props;
    const date = new Date(props.timeStamp);
    if (props.worker === undefined) props.worker = "Aucun worker"
    const { classes, cx } = useStyles({ status: props.status });
    const [opened, setOpened] = useState(false);
    const [cancel,setCancel] = useState(false);
    const [editMode,setEditMode] = useState(false);
    const status = props.status;
    const form = useForm({initialValues:props})

    return (
        <>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                size="75%"
            >
                <Button onClick={()=> setEditMode(!editMode)} >Edit</Button>
                <Grid my={"xs"}>
                    <Grid.Col span={1}>
                        <ThemeIcon
                            size="xl"
                            radius="md"
                            variant="gradient"
                            gradient={{ deg: 0, from: 'pink', to: 'orange' }}
                        >
                        </ThemeIcon>
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <Text tt={"capitalize"} size="xl" weight={500} ta="left">
                           {date.toLocaleDateString('fr-CA',dateOptions)}
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <Stack>
                          {props.worker ? <Text size="md" ta="center">Travailleur : {props.worker.prenom +" "+props.worker.nom}</Text> : <></> }  
                           {props.member ? <Text size={"md"} ta={"center"}>Membre : {props.member.prenom +" "+props.member.nom}</Text>:<></>} 
                        </Stack>
                    </Grid.Col>
                </Grid>
                {!editMode ? 
                <>  
                    <Text size="lg" weight={500} mt={'md'}>
                                Montant : {props.amount} $
                            </Text>
                    <Text weight={"bolder"}>Description : </Text>
                    <Paper mb={"md"} shadow="sm" p="xs" withBorder>
                        {parse(props.description)}
                    </Paper>
                </>
                :
                    <form>
                    <Text size="lg" weight={500} mt={'md'}>
                        Montant :
                         {props.amount} $
                     </Text>
                    <Text weight={"bolder"}>Description : </Text>
                         <TextInput {...form.getInputProps("description")} />
                        <Button onClick={()=>modifyPayment(props._id,form.values)}>Enregistrer les changements</Button>
                    </form>
                     }


                <Group position="center" spacing="xl" grow>
                <Button color="green" onClick={() => { receivePayment(props._id); setOpened(false) }}>Accuser de réception</Button>
                    <Button color="orange" onClick={() => { setCancel(true);  }}>Annuler le paiement</Button>
                    <Button color="red" onClick={() => { deletePayment(props._id); setOpened(false) }}>Supprimer le paiement</Button>
                </Group>
            </Modal>
            <Modal opened={cancel} onClose={()=>setCancel(false)}>
            <CancelPaymentPrompt PaymentID={props._id} backOut={()=>setCancel(false)} />
            </Modal>
            <Paper withBorder onClick={() => { setOpened(true) }} radius="md" className={cx(classes.card, { [classes.cardClosed]: status === "closed", [classes.cardProg]: status === "inProg" })}>
                <Grid>
                    <Grid.Col span={1}>
                        <ThemeIcon
                            size="xl"
                            radius="md"
                            variant="gradient"
                            gradient={{ deg: 0, from: 'pink', to: 'orange' }}
                        >
                        {getIcon(props.type)}
                        </ThemeIcon>
                    </Grid.Col>
                    <Grid.Col span={1}>
                        {getPaymentStatus(props.status)}
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Text size="xl" weight={500} mt="md">
                        {date.toLocaleDateString('fr-CA',dateOptions)}
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Text size="lg" weight={500} mt={'md'}>
                            Montant : {props.amount} $
                        </Text>
                    </Grid.Col>
                </Grid>
                <Text>{props.type}</Text>
            </Paper >
        </>
    
    );
   
}

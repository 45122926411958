import { useState } from "react";
import { createStyles, Paper, Text, ThemeIcon, Group, Modal, Grid, Button, Stack } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { IconCar, IconPhone } from '@tabler/icons-react';
import { useMutation } from 'react-query';
import { Link } from "react-router-dom";
import FollowUp from "../chrono/followUp";
import {closeChrono,inProgChrono,postFollowUp} from "../connections/requests";
const parse = require('html-react-parser');

const useStyles = createStyles((theme) => ({
    card: {
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        transition: 'transform 150ms ease, box-shadow 100ms ease',
        padding: theme.spacing.xl,
        paddingLeft: theme.spacing.xl * 2,

        '&:hover': {
            boxShadow: theme.shadows.md,
            transform: 'scale(1.02)',
        },

        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.green[6], theme.colors.blue[6]),
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.green[6], theme.colors.blue[6]),
        },
    }, cardClosed: {
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.red[4], theme.colors.orange[9]),
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.red[4], theme.colors.orange[9]),
        }

    }, cardProg: {
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.orange[4], theme.colors.yellow[9]),
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            width: 6,
            backgroundImage: theme.fn.linearGradient(0, theme.colors.orange[4], theme.colors.yellow[9]),
        }
    }

    , buttons: {
        position: "absolute",
        right: '1rem',
        top: '1rem',
    }
}));

function getIcon(type) {
    switch (type) {
        case "transport":
            return <IconCar size={28} stroke={1.5} />
        case "phone":
            return <IconPhone size={28} stroke={1.5} />

        default:
            break;
    }
};




const content = '';

export function ChronoCard(_props) {
    const props = _props.props;
    const { classes, cx } = useStyles({ status: props.status });
    const mutation = useMutation((props) => postFollowUp(props));
    const closeMutation = useMutation((id) => closeChrono(id, setStatus("closed")));
    const progMutation = useMutation((id) => inProgChrono(id, setStatus("inProg")));
    const [opened, setOpened] = useState(false);
    const [status, setStatus] = useState(props.status);
    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            SubScript,
            Highlight,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
        content,
    });
    return (
        <>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                size="75%"

            >
                <Grid my={"xs"}>
                    <Grid.Col span={1}>
                        <ThemeIcon
                            size="xl"
                            radius="md"
                            variant="gradient"
                            gradient={{ deg: 0, from: 'pink', to: 'orange' }}
                        >
                            {getIcon(props.type)}
                        </ThemeIcon>
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <Text tt={"capitalize"} size="xl" weight={500} ta="left">
                            {props.titre}
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <Stack>
                            <Text size="md" ta="center">Travailleur : {props?.worker?.prenom +" "+props?.worker?.nom}</Text>
                            <Text size={"md"} ta={"center"}>Membre : {props?.member?.fullName}</Text>
                            <>
                            {props?.sudomember?.nom ? <Text>Nom : {props?.sudomember?.nom}</Text>:null}
                            {props?.sudomember?.mail ? <Text>Courriel : {props?.sudomember?.mail}</Text>:null}
                            {props?.sudomember?.phone ? <Text>Téléphone : {props?.sudomember?.phone}</Text>:null}
                            </>
                        </Stack>
                    </Grid.Col>
                </Grid>
                <Text weight={"bolder"}>Description : </Text>
                <Paper mb={"md"} shadow="sm" p="xs" withBorder>
                    {parse(props.description)}
                </Paper>
                <Text weight={"bolder"}>Suivis : </Text>
                <Paper my={"md"} shadow="sm" p="xs" withBorder>
                    {(props.followUp.length > 0) ? props.followUp.map(i => <FollowUp suivi={i} />) : <Text> Aucun Suivi</Text>}
                </Paper>
                <Text weight={"bolder"}>Ajouter un suivi : </Text>
                <RichTextEditor my={"lg"} py={"sm"} editor={editor}>
                    <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Bold />
                            <RichTextEditor.Italic />
                            <RichTextEditor.Underline />
                            <RichTextEditor.Strikethrough />
                            <RichTextEditor.ClearFormatting />
                            <RichTextEditor.Highlight />
                            <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.H1 />
                            <RichTextEditor.H2 />
                            <RichTextEditor.H3 />
                            <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Blockquote />
                            <RichTextEditor.Hr />
                            <RichTextEditor.BulletList />
                            <RichTextEditor.OrderedList />
                            <RichTextEditor.Subscript />
                            <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Link />
                            <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.AlignLeft />
                            <RichTextEditor.AlignCenter />
                            <RichTextEditor.AlignJustify />
                            <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                    </RichTextEditor.Toolbar>
                    <RichTextEditor.Content />

                </RichTextEditor>

                <Group position="center" spacing="xl" grow>
                    <Button component={Link} to={props._id} color="violet">Modifier le suivi</Button>
                    <Button onClick={() => mutation.mutate({ id: props._id, followUps: props.followUp, newFollowUp: editor.getHTML() })}>Ajouter Suivi</Button>
                    <Button color="orange" onClick={() => { progMutation.mutate(props._id); setOpened(false) }}>Mettre en attente</Button>
                    <Button color="red" onClick={() => { closeMutation.mutate(props._id); setOpened(false) }}>Fermer le suivi</Button>
                </Group>
            </Modal>
            <Paper id={props._id} withBorder onClick={() => { setOpened(true) }} radius="md" className={cx(classes.card, { [classes.cardClosed]: status === "closed", [classes.cardProg]: status === "inProg" })}>
                <Grid>
                    <Grid.Col span={1}>
                        <ThemeIcon
                            size="xl"
                            radius="md"
                            variant="gradient"
                            gradient={{ deg: 0, from: 'pink', to: 'orange' }}
                        >
                            {getIcon(props.type)}
                        </ThemeIcon>
                    </Grid.Col>
                    <Grid.Col span={10}>
                        <Text size="xl" weight={500} mt="md">
                            {props.titre}
                        </Text>
                        {props?.member ?  <Text size={"lg"} italic weight={400} mt={"md"}>Membre : <Link to={`/auth/member/${props?.member?._id}`}>{`${props?.member?.prenom}  ${props?.member?.nom}`}</Link></Text> : 
                        <Text size={"lg"} italic weight={400} mt={"md"}>Nom : {props?.member?.nom} Email : {props?.member?.mail} Téléphone : {props?.member?.phone}</Text>
                        }
                    </Grid.Col>
                </Grid>
                <div >
                    {parse(props.description)}
                </div>
                <Text>{props.timeStamp}</Text>
            </Paper >
        </>
    );
}
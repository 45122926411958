import {  Text,  Card, Divider, useMantineTheme, Title } from '@mantine/core';


function getDayBefore(dateStart) {
    let today = new Date(Date.now());
    let event = new Date(dateStart);
    event.setFullYear(event.getFullYear() + 1);
    let diff = Math.round((event - today) / (1000 * 60 * 60 * 24));
    if (diff < 0) return <Text color='red'>Inactif</Text>
    if (diff < 1) return <Text color='red'>{`Aujourd'hui`}</Text>
    if (diff < 7) return <Text color='orange'>{`Moins qu'une semaine`}</Text>
    return <Text color='green'>{`Il reste ${diff} jours`}</Text>
}

export function MemberInfo(props) {
    const theme = useMantineTheme();
    let data = { genre: "", dateOfBirth: Date.now() }
    data = { ...props.props }
    return (
        <Card
            radius="md"
            withBorder
            p="lg"
            sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
            })}
        >
            <Card.Section bg={theme.colors.blue} p={"xs"} >
                <Title color='white' align="center" order={3}  >
                    Informations
                </Title>
            </Card.Section>
            <Card.Section m={"xs"}>
                <Text tt={'capitalize'} align="left">
                    Genre : {data.genre}
                </Text>
                <Text align="left">
                    Date de Naissance : {data.dateOfBirth ? new Date(data.dateOfBirth).toLocaleDateString('fr-ca', { dateStyle: "long" }) : "Aucune date de naissance au dossier"}
                </Text>
                <Text align="left">
                    Date d'adhésion : {new Date(data.dateOfRegistration).toLocaleDateString('fr-ca', { dateStyle: "long" })}
                    {getDayBefore(data.dateOfMembership)}
                </Text>
            </Card.Section>
            <Divider my={"md"} />
            <Text align='left'>Adresse Civique : {data.civic ? data.civic : "Aucune adresse au dossier"}</Text>
            <Text align='left'>Ville : {data.city ? data.city : "Aucune ville au dossier"}</Text>
            <Text align='left'>Code Postal : {data.postal ? data.postal : "Aucun code au dossier"}</Text>
            <Text align="left">Notes : {data.note ? data.note : "Aucune note"}</Text>

        </Card>
    );
}
import {
    createStyles,
    Container,
    Text,
    Header,
    Button,
    Grid
} from '@mantine/core';
import HeadBadge from './HeadBadge';
import { resetQueries } from '../connections/requests';


const useStyles = createStyles((theme) => ({
    header: {
        paddingTop: theme.spacing.sm,

        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.blue[9],
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? 'transparent' : theme.colors.blue[9]
            }`,

    },

    mainSection: {
        paddingBottom: theme.spacing.sm,
    },

    user: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
        borderRadius: theme.radius.sm,
        transition: 'background-color 100ms ease',

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        },

        [theme.fn.smallerThan('xs')]: {
            display: 'none',
        },
    },

    burger: {
        [theme.fn.largerThan('xs')]: {
            display: 'none',
        },
    },

    userActive: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    },

    tabs: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    tabsList: {
        borderBottom: '0 !important',
    },

    tab: {
        fontWeight: 500,
        height: 38,
        backgroundColor: 'transparent',

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
        },

        '&[data-active]': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
        },
    },
}));



export function NavigationHead(props) {
    const { classes } = useStyles();
    return (
        <Header height={81} className={classes.header}>
            <Container maw={'88%'} className={classes.mainSection}>
            <Grid>
            <Grid.Col span={3}>
            <Text size='2em' weight='bolder' variant="gradient" gradient={{ from: 'yellow', to: 'white', deg: 45 }}>ADSMQ 2.0</Text>
            </Grid.Col>
            <Grid.Col span={5}></Grid.Col>
            <Grid.Col span={3}>
                <Button onClick={()=>resetQueries()} color='violet'>Réinitialiser les données</Button>
            </Grid.Col>
            <Grid.Col span={1}>
            <HeadBadge/>
            </Grid.Col>
            </Grid>
            </Container>
        </Header>
    );
}
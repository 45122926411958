import React from 'react'
import { IconUserDollar,IconGift,IconCalendarDollar } from '@tabler/icons-react'
import { Text,Flex } from '@mantine/core';

export default function PaymentTypeIcon(type) {
    if(type==="renouvellement") 
     return (<Flex gap={"sm"}>
    <IconCalendarDollar color='blue'/>
    <Text tt={"capitalize"} color='blue'>{"Renouv..."}</Text>
    </Flex>)
    if(type === "don")
     return (<Flex gap={"sm"}>
    <IconGift color='green'/>
    <Text tt={"capitalize"} color='green'>{type}</Text>
    </Flex>)
    if(type === "adhésion")
     return (<Flex gap={"sm"}>
    <IconUserDollar color='purple'/>
    <Text tt={"capitalize"} color='purple'>{type}</Text>
    </Flex>)
    if(type === "activité")
     return (<Flex gap={"sm"}>
    <IconCalendarDollar color='orange'/>
    <Text tt={"capitalize"} color='orange'>{type}</Text>
    </Flex>)

}

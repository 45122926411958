import { Flex, Stack, Text, TextInput, Button, Radio, Select, Textarea } from '@mantine/core'
import { DatePicker } from '@mantine/dates';
import 'dayjs/locale/fr-ca';
import { useForm } from '@mantine/form'
import React from 'react'
import { patchWorker } from '../connections/requests';

export default function WorkerInfoEdit(props) {
    let { prenom, nom } = props.worker
    const form = useForm({ initialValues: { ...props.worker } })
    return (
        <form>
            <Text size={"lg"} c={"dimmed"} align='center'>Modifier les infos pour l'utilisateur {`${prenom} ${nom}`}</Text>
            <Stack>
            
                <Flex gap={"md"}>
                    <TextInput label="Prénom"  {...form.getInputProps('prenom')} />
                    <TextInput label="Nom" {...form.getInputProps('nom')} />
                </Flex>
                <Radio.Group name="gender" {...form.getInputProps('gender')} label="Genre">
                    <Radio value="homme" label="Homme" />
                    <Radio value="femme" label="Femme" />
                    {// <Radio value="nonbinaire" label="Non-Binaire / Autre" />
                    }
                </Radio.Group>
                
                <TextInput label="Adresse Civique"  {...form.getInputProps('civic')} />
                <TextInput label="Ville" {...form.getInputProps("city")}/>
                <TextInput label="Code Postal"   {...form.getInputProps('postal')} />
                <TextInput label="Adresse Courriel" {...form.getInputProps('email')}/>
                <TextInput label="Téléphone"  {...form.getInputProps('phone')} />
 
                <Select
                    style={{ marginTop: 20, zIndex: 2 }}
                    data={['Inter', 'Benevole', 'Admin', 'Collab']}
                    placeholder={"Status utilisateur"}
                    label="Status utilisateur"
                    {...form.getInputProps('rank')}
                />
                <Textarea label="Ajouter des notes" {...form.getInputProps('note')}/>
            </Stack>
            <Button onClick={() => patchWorker(form)}>Soumettre</Button>
        </form>

    )
}

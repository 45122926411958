import React from 'react'
import { IconUserDollar,IconGift,IconCalendarDollar } from '@tabler/icons-react'
import { Text,Flex } from '@mantine/core';

export default function RunTypeIcon(type) {
    if(type==="déplacement") 
        return (<Flex gap={"sm"}>
    <IconCalendarDollar color='blue'/>
    <Text tt={"capitalize"} color='blue'>{"Déplac..."}</Text>
    </Flex>)
    if(type === "visite")
        return (<Flex gap={"sm"}>
    <IconGift color='green'/>
    <Text tt={"capitalize"} color='green'>{type}</Text>
    </Flex>)
    if(type === "rencontre")
        return (<Flex gap={"sm"}>
    <IconUserDollar color='purple'/>
    <Text tt={"capitalize"} color='purple'>{type}</Text>
    </Flex>)
    if(type === "activité")
        return (<Flex gap={"sm"}>
    <IconCalendarDollar color='orange'/>
    <Text tt={"capitalize"} color='orange'>{type}</Text>
    </Flex>)
        return (<Flex gap={"sm"}>
        
        <Text tt={"capitalize"} color='grey'>{"Autres"}</Text>
        </Flex>)

}

import React from 'react'
import { useQuery } from 'react-query'
import { fetchVolunteerStatsByWorker } from '../connections/requests';
import { Flex, Paper } from '@mantine/core';

export default function VolunteerStats({worker}) {
    const statQuery = useQuery(["jobs","volunteer",worker],fetchVolunteerStatsByWorker);
    console.log(statQuery.data)
    if(statQuery.status === "success"){
    return (
            <p>test</p>
    )

}
return "loading"
}



const theme = {
    colors: {
        "papyrus": [
            "#faf0e6",
            "#f8e8db",
            "#f5e1cf",
            "#f2d9c4",
            "#efd2b8",
            "#ebcaac",
            "#e8c29f",
            "#e5bb93",
            "#e2b385",
            "#d9b99b"
        ]
    },

}



export default theme
import React from 'react'
import {Button, Card, Container, Paper, PasswordInput, Stack, Title} from "@mantine/core";
import {Routes,Route} from "react-router-dom";
import { useForm } from '@mantine/form';
import { changePassword } from '../connections/requests';
import { useQuery } from 'react-query';
import { getUser } from '../connections/requests';


export default function UserPage() {
  return (
    <Routes>
        <Route path='' element={<MainPage/>}/>
    </Routes>
  )
}
function MainPage(){
    return (
        <Container my={"lg"}>
            <Paper py={"md"} my={"xl"}>
            <Title ta={'center'}>Usager</Title>
            </Paper>
            <ChangePassword/>
        </Container>
)};
function ChangePassword(){
  const form = useForm(
    {
      initialValues:{id:getUser(),oldPass:"",newPass:"",confPass:""},
      validate:{
        newPass: (_,values) => (values.newPass === values.confPass? null : "mot de passe différent"),
        confPass: (_,values) => (values.newPass === values.confPass? null : "mot de passe différent")
      }
    }
  ) 
  return(
    <Card>
      <Card.Section>
        <Title order={2} ta={"center"}>Changer le mot de passe</Title>
      </Card.Section>
      <Card.Section m={"lg"}>
        <form onSubmit={form.onSubmit((values)=> changePassword(values))}>
          <Stack align='center'>
          <PasswordInput w={{ base: 200, sm: 400, lg: 500 }} label="Ancien mot de passe" {...form.getInputProps("oldPass")}/>
          <PasswordInput w={{ base: 200, sm: 400, lg: 500 }} label="Nouveau mot de passe" {...form.getInputProps("newPass")}/>
          <PasswordInput w={{ base: 200, sm: 400, lg: 500 }} label="Confirmer mot de passe" {...form.getInputProps("confPass")}/>
          <Button mx={'xl'} type='submit'>Soumettre</Button>
          </Stack>
        </form>
      </Card.Section>
    </Card>
   )
}
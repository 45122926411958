import  Cookie  from 'universal-cookie';
import axios from 'axios';
const outURL = "https://data.adsmqam.org:4000/";
const inURL = "http://192.168.0.118:3000/";
const localURL = "http://localhost:4001/";
const fullUrl = `${window.location.origin}`;

var token = "";
const cookies = new Cookie()
function setURL(){
    console.log(fullUrl);
    if(fullUrl === "https://data.adsmqam.org") {
        console.log("out")
        cookies.set("CONN","out");
        return;
    }
    if(fullUrl === "http://192.168.0.118"){
        console.log("local")
        cookies.set("CONN", "local");
        return;
    }
    if(fullUrl === "http://localhost:3000"){
        console.log("dev")
        cookies.set("CONN","dev");
        return;
    }

}
function getToken(){
    return token;
}
function setToken(newToken){
 token = newToken;
}
async function testURL(){
 await axios({method:"get",url:localURL}).then(cookies.set("CONN","dev")).catch(async e => await axios({method:"get",url:outURL}).then(cookies.set("CONN","out")).catch(e => cookies.set("CONN","local")))

}
function getURL(){
    // if(!cookies.get("CONN")) testURL().then(getURL());
    if(!cookies.get("CONN")) return setURL();
    if(cookies.get("CONN")==="local") return inURL;
    if(cookies.get("CONN")==="dev") return localURL;
    return outURL;

}
setURL();
export {
    setToken,
    getToken,
    getURL,
    testURL,
    outURL
}

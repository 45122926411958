import { Flex,  Stack,  TextInput, Button, Radio,   Title, Textarea } from '@mantine/core'
import { Calendar, TimeRangeInput } from '@mantine/dates';
import 'dayjs/locale/fr-ca';
import { useForm } from '@mantine/form'
import React from 'react'
import { editEventByID } from '../connections/requests';



export default function EventInfoEdit(props) {
    let { title, dateStart, dateEnd } = props.event
    const form = useForm({
        initialValues: { ...props.event, date: new Date(dateStart), time: [new Date(dateStart), new Date(dateEnd)] },
        transformValues: (values) => ({
            ...values,
            dateStart: new Date(values.date.getFullYear(), values.date.getMonth(), values.date.getDay(), values.time[0].getHours(), values.time[0].getMinutes()),
            dateEnd: new Date(values.date.getFullYear(), values.date.getMonth(), values.date.getDay(), values.time[1].getHours(), values.time[1].getMinutes())
        })
    })
    return (
        <form>
            <Title order={2}>Modifier les infos pour l'événement {`${title}`}</Title>
            <Stack>
                <TextInput label="Titre"  {...form.getInputProps('title')} />
                <Textarea label="Description" {...form.getInputProps("description")} />
                <Radio.Group name="medium" {...form.getInputProps('medium')} label="Medium">
                    <Radio value="presentiel" label="Présentiel" />
                    <Radio value="videocall" label="Video Conference" />
                </Radio.Group>
                <Radio.Group name="type" {...form.getInputProps('type')} label="Type">
                    <Radio value="culturel" label="Culturel" />
                    <Radio value="reunion" label="Réunion" />
                </Radio.Group>
                <TextInput label="Lieu / Lien "  {...form.getInputProps('location')} />
                <Flex justify={"center"} align={"center"} gap={"xl"}  >
                    <Calendar value={form.values.date} {...form.getInputProps("date")} />
                    <TimeRangeInput label="Temps de l'événement" {...form.getInputProps("time")} />
                </Flex >
            </Stack>
            <Button onClick={() => editEventByID(form)}></Button>
        </form>

    )
}

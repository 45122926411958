import React, { useState } from 'react';
import {Link} from "react-router-dom";
import { Menu, Text,ActionIcon, Modal, TextInput, Button, Stack } from '@mantine/core';
import { IconDots } from '@tabler/icons-react';
import { deletePayment,cancelPayment,receivePayment } from '../../connections/requests';
import PaymentStatusIcon from './PaymentStatusIcon';
import PaymentTypeIcon from './PaymentTypeIcon';
import { useDisclosure } from '@mantine/hooks';

export default function PaymentRow(props) {
  const [opened,handlers] = useDisclosure(false)
  const [cancelNote,setCancelNote] = useState("");
    let row = props?.row;
   let date = new Date(row?.deadline).toLocaleDateString("fr-fr")
  return (
    <>
    <Modal opened={opened} onClose={()=>handlers.close}>
      <Stack>
        <Text>Raison de l'annulation</Text>
        <TextInput value={cancelNote} onChange={(event) => setCancelNote(event.currentTarget.value)} />
        <Button my={"md"} color='red' onClick={()=>cancelPayment(row._id,cancelNote)}>Annuler</Button>
      </Stack>
    </Modal>
    <tr  key={row?._id}>
    <td><Text component={Link} to={row?._id}>{row?.title}</Text></td>
    <td>{row?.provider}</td>
    <td>{row?.amount}</td>
    <td>{date}</td>
    <td><Menu shadow="md" width={200}>
      <Menu.Target>
        <ActionIcon>
            <IconDots size={22}/>
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={()=>deletePayment(row._id)}>Supprimer</Menu.Item>
        <Menu.Item onClick={()=>handlers.open()}>Annuler</Menu.Item>
        <Menu.Item onClick={()=>receivePayment(row._id)}>Reçu</Menu.Item>
      </Menu.Dropdown>
    </Menu></td>
</tr>
</>
  )
}



import React from 'react'
import { Container, Title,Card, useMantineTheme } from '@mantine/core';
import {Route,Routes} from "react-router-dom";
import Event from '../event/eventPage';
import { EventTable } from '../event/eventTable';



export default function EventPage() {
    return (
    <>
        
        <Routes>
            <Route path="" element={<MainPage />} />
            <Route path=":event" element={<Event />} />
        </Routes>
    </>
    )
}
function MainPage(){
    const theme = useMantineTheme();
    return(
        <Container py={"lg"} >
            <Card withBorder>
                <Card.Section p={"md"}  bg={theme.colors.blue}>
                    <Title c={"white"} order={1} ta={"center"}>Activités</Title>
                </Card.Section>
                <Card.Section p={"md"}>
                    <EventTable/>
                </Card.Section>
            </Card>
        </Container>
        )
}
import React from 'react'
import { ChronoCard } from "../member/chronoCardModal"
import { useQuery } from 'react-query';
import { Button, Text, Stack } from '@mantine/core';
import {  fetchAllActiveChronos, fetchAllMemberlessChronos } from '../connections/requests';

export function ChronoBoard({range}) {


    const { data, status, refetch } = useQuery(['chrono',range], getFetchQuery(range));

    return (
        <div>
        
            <Stack py={"lg"}>
                {status === "success" ?
                <>
                    { data.map((i) => (<ChronoCard refetch={() => refetch()} props={i} key={i._id} />))}
                </>
                    :
                    <Text>No Data</Text>}
            </Stack>
        </div>
    )
}



function getFetchQuery(range){
    if(range === "active") return fetchAllActiveChronos;
    if(range === "memberless") return fetchAllMemberlessChronos;
    return fetchAllActiveChronos;
}
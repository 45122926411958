import React from 'react'
import { ChronoCard } from "../member/chronoCardModal"
import { useQuery } from 'react-query';
import { Button, Text, Stack } from '@mantine/core';


export function ChronoBoard({grant}) {



    return (
        <div>
            <Stack py={"lg"}>
                {true ?
                <>
                    { grant?.chronos?.map((i) => (<ChronoCard  props={i} key={i._id} />))}
                </>
                    :
                    <Text>No Data</Text>}
            </Stack>
        </div>
    )
}



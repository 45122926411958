import React from 'react'
import {Card, Center,SimpleGrid,Stack,Title,Loader} from "@mantine/core";
import { useQuery } from 'react-query'
import { fetchJobsDayByWorkerID } from '../connections/requests'
import DayCard from './DayCard'
import CreateDayJob from './CreateDayJob';


export default function DayBoard({workerID}) {
   const dayQuery = useQuery(['day',workerID],fetchJobsDayByWorkerID)
    return (
        <Card m={"lg"} >
            <Card.Section bg={"blue"}>
                <Title c={"white"} order={3} ta={"center"}>Horaire de travail</Title>
            </Card.Section>
            <Card.Section>
                <CreateDayJob/>
            </Card.Section>
            <SimpleGrid cols={5}>
                {dayQuery.status==="success" ? dayQuery.data.map(e => <DayCard key={e.date} _id={e._id} date={e.date} hours={e.hours} />):<Loader/>}
            </SimpleGrid>
        </Card>
    )
}

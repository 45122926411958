import React from 'react'
import {Text,Flex} from "@mantine/core";

export default function AllergyBadge({allergies}) {

    if(!allergies) return <Text>Allergies : Aucune allergie</Text>
    return (
    <Flex>
    {allergies?.map(e => <Text>{e}</Text>)}
    </Flex>
    )
}

import React, { useState } from 'react';
import {Link} from "react-router-dom";
import { Menu, Text,ActionIcon, Modal, TextInput, Button, Stack,Flex } from '@mantine/core';
import { IconCurrency, IconCurrencyDollar, IconDots } from '@tabler/icons-react';
import { deleteJobByID,cancelPayment,receivePayment, createOnePayment } from '../../connections/requests';
import PaymentStatusIcon from './PaymentStatusIcon';
import PaymentTypeIcon from './PaymentTypeIcon';
import { useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';

export default function PaymentRow(props) {
  const [opened,handlers] = useDisclosure(false)
  let row = props?.row;
  const form = useForm(
    {initialValues:
      {job:row?._id,member:row?.member?._id,worker:row?.worker?._id,amount:row?.amount,type:"refund",description:"",status:"paid",timeStamp:new Date()}
    }
  );
  return (
    <>
    <Modal title={"Remboursement"} opened={opened} onClose={()=>handlers.close()}>
      <form>
        <Stack>
          <TextInput {...form.getInputProps("description")}/>
          <Button onClick={()=>{createOnePayment(form.values).then(handlers.close())}}>Confirmer</Button>
        </Stack>
      </form>
      
    </Modal>
    <tr key={row?._id}>
    <td><Text  component={row?.worker?._id ? Link : Text} to={ "/auth/team/"+row?.worker?._id} style={{ textDecoration: 'none',  }}>{!row.worker ? "aucun utilisateur" : row?.worker?.prenom + " " + row?.worker?.nom}</Text></td>
    <td><Text>{row?.distance ? row.distance + " km": "0 km"}</Text></td>
    <td>{row.hourlength+" heure(s)"}</td>
    <td><Flex justify={"space-around"}>{PaymentStatusIcon(row?.refunded,row?.refund)}<ActionIcon onClick={()=>handlers.open()}><IconCurrencyDollar/></ActionIcon></Flex></td>
    <td>{new Date(row?.date).toLocaleDateString('fr-FR')}</td>
    <td><Menu shadow="md" width={200}>
      <Menu.Target>
        <ActionIcon>
            <IconDots size={22}/>
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={()=>deleteJobByID(row._id)}>Supprimer</Menu.Item>
      </Menu.Dropdown>
    </Menu></td>
</tr>
</>
  )
}



import {  Text,  Card, Divider, useMantineTheme, Title,Flex,Stack } from '@mantine/core';
import TransportBadge from './component/TransportBadge';
import { IconWheelchair } from '@tabler/icons-react';
import AllergyBadge from './component/AllergyBadge';


export function MemberTraits(props) {
    const theme = useMantineTheme();
    let data = { genre: "", dateOfBirth: Date.now() }
    data = { ...props.props }
    return (
        <Card
            radius="md"
            withBorder
            p="lg"
            sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
            })}
        >
            <Card.Section bg={theme.colors.blue} p={"xs"} >
                <Title color='white' align="center" order={3}  >
                    Informations
                </Title>
            </Card.Section>
            <Card.Section m={"xs"}>
                <Stack>
                <Flex>
                    <Text align='left' tt={"capitalize"}> Transport : {data?.transportType} </Text>
                    <Text></Text>
                    <TransportBadge transportType={data?.transportType}/>
                </Flex>
                <Flex>
                    <AllergyBadge props={data}/>
                    
                </Flex>
                <Flex>
                    <Text mr={"md"} align='left'>Appareils : aucun</Text>    
                    <Text> Emprunté : NON</Text>
                </Flex>
                <Flex>
                    Système MF : NON
                </Flex>
                    <Flex>
                        <Text> ATS : Aucun </Text>
                    </Flex>
                </Stack>
            </Card.Section>
            <Divider my={"md"} />
        </Card>
    );
}
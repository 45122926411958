import React from 'react'
import {Badge} from "@mantine/core";

function getTypeChip(type) {
    if (type === "Régulier") return <Badge color='blue' variant='outline'>Régulier</Badge>
    if (type === "Non-Membre") return <Badge color='gray' variant='outline'>Non-Membre</Badge>
    if (type === "Sympathisant") return <Badge color='cyan' variant='outline'>Sympathisant</Badge>
    if (type === "Autres") return <Badge color='red' variant='outline'>Autres</Badge>
    else return <Badge color='red' variant='outline'>ERREUR</Badge>
}

export default function MemberBadge(props) {
    const {type} = props;
  return (
    getTypeChip(type)
  )
}

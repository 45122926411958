import React from 'react'
import {Paper,Flex, Title,Text} from "@mantine/core";
import { Link } from 'react-router-dom';
import { IconNotebook } from '@tabler/icons-react';

function getPriorityStyle(card){
  let classnames = {};
  if(Date.now()-new Date(card.timeStamp).valueOf()>=(1000*60*60*24*7*3)) classnames.bg = "red";
  else if(Date.now()-new Date(card.timeStamp).valueOf()>=(1000*60*60*24*7)) classnames.bg = "orange";
  else  classnames.bg = "green";
    return classnames
}

export default function DashCard({cardType,card}) {
    if(cardType === "chrono")
  return (
    <Paper withBorder p={"sm"}  my={"xs"} component={Link} to={"/auth/chrono/"+card._id} {...getPriorityStyle(card)} >
        <Flex>
            <IconNotebook mx={"lg"} color='white'/>
            <Title mx={"md"} truncate color='white' ta={"center"} align='center' inline order={3}>{card.titre}</Title>
            <Text mx={"md"}  color='white' tt={"capitalize"} td={"underline"}>{card.member.prenom+" "+card.member.nom}</Text>
            <Text mx={"md"} color='white' >{new Date(card.timeStamp).toLocaleDateString("fr-FR")}</Text>
            <Text mx={"md"} color='white'>{Math.floor(((Date.now()-new Date(card.timeStamp).valueOf())/(1000*60*60*24)))} jours</Text>
        </Flex>
    </Paper>
  )
}

import {  Text,  Card, Divider, useMantineTheme, Title } from '@mantine/core';



export function WorkerInfo(props) {
    const theme = useMantineTheme();
    let data = { ...props.props }
    return (
        <Card
            radius="md"
            withBorder
            p="lg"
            sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
            })}
        >
            <Card.Section bg={theme.colors.blue} p={"xs"} >
                <Title color='white' align="center" order={3}  >
                    Informations
                </Title>
            </Card.Section>
            <Card.Section m={"xs"}>
                <Text tt={'capitalize'} align="left">
                    Genre : {data.gender ? data.gender : "Genre non spécifié"}
                </Text>

            </Card.Section>
            <Divider my={"md"} />
            <Text align='left'>Adresse Civile : {data.civic ? data.civic : "Aucune adresse au dossier"}</Text>
            <Text align='left'>Ville : {data.city ? data.city : "Aucune ville au dossier"}</Text>
            <Text align='left'>Adresse Postale : {data.postal ? data.postal : "Aucune adresse au dossier"}</Text>
            <Text align="left">Notes : {data.note ? data.note : "Aucune note"}</Text>

        </Card>
    );
}
import {  Stack, Text,  Button, NumberInput, Textarea } from '@mantine/core'
import { DatePicker } from '@mantine/dates';
import 'dayjs/locale/fr-ca';
import { useForm } from '@mantine/form'
import React from 'react'
import { outURL } from '../connection';

import { showNotification } from '@mantine/notifications';
import axios from 'axios'
async function editMember(f, c, m) {
    const data = f.values;;
    const res = axios({
        method: "post",
        url: `${outURL}member/payment`,
        data: data
    }).then((response) => showNotification({ title: 'Paiement Enregistré !', message: `Le membre ${f.values.prenom} ${f.values.nom} est bien modifié` }))
        .catch((e) => showNotification({ title: 'Erreur !', message: `Une erreur s'est produite`, color: "red" }));
    return res;
}

export default function MemberPayment(props) {
    let { prenom, nom } = props.member
    const form = useForm({ initialValues: { member:props.member, payment:{dateOfPayment: new Date(),montant:0,reason:"" } }})
    return (
        <form>
            <Text>Paiement reçu de {`${prenom} ${nom}`}</Text>
            <Stack>
                <DatePicker
                    inputFormat='DD MMMM YYYY'
                    labelFormat='DD/MMMM/YYYY'
                    locale='fr-ca'
                    style={{ marginTop: 20 }}
                    label="Date du paiement"
                    allowFreeInput
                    clearable={false}
                    {...form.getInputProps('payment.dateOfPayment')}
                />
                    <NumberInput
                        label="Price"
                         defaultValue={1000}
                         {...form.getInputProps('payment.montant')}
                         parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                         formatter={(value) =>
                        !Number.isNaN(parseFloat(value))
                         ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{2}$)+(?!\d))/g , ',')
                              : '$ '
      }
    />
            <Textarea label="Raison" {...form.getInputProps('payment.reason')} />
                
            </Stack>
            <Button my={"xl"} onClick={() => editMember(form)}>Soumettre</Button>
        </form>

    )
}

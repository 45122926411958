import { Button, Modal, Paper } from '@mantine/core'
import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { fetchGrantByID } from "../connections/requests";
import GrantMission from './GrantMission';
import GrantInfo from './GrantInfo';
import { ChronoBoard } from './GrantChrono';
import { Chrono } from './chrono';
import { useToggle } from '@mantine/hooks';

export default function GrantPage() {
    const GrantID = useParams()?.grant;
    const paymentQuery = useQuery(["grant",GrantID],fetchGrantByID)
    const [modal,setModal] = useState(false)
    if(paymentQuery.status==="success" && paymentQuery.data)
    return (
        <Paper>
            <Modal
            size={"xl"}
            opened={modal}
            onClose={()=> setModal(false)}
            withCloseButton={false}
            >
                <Chrono/>
            </Modal>
            <GrantInfo grant={paymentQuery?.data}/>
            <Button onClick={()=> setModal(true)}>Ajouter un suivi</Button>
            <ChronoBoard grant={paymentQuery?.data}/>
        </Paper>
    )
}

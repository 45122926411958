import {useState} from 'react';
import { useQuery } from 'react-query';
import { getUser,fetchAllByWorker } from '../connections/requests';
import { Card, Title,Chip, Center,Text, Divider, Flex, Skeleton, SimpleGrid } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Link } from 'react-router-dom';
const parse = require('html-react-parser');

export default function FlowBoard() {
    let workerId = getUser();
    console.log(workerId)
    const flowQuery = useQuery(['butler','flow',workerId], fetchAllByWorker);
    const [filters,setFilters] = useState(["chrono","note","run","active","waiting"]);
    const [opened,handlers] = useDisclosure(false);
  return (
    <Card radius={"lg"} m={"lg"}>
        <Card.Section  bg={"blue"}>
            <Title order={2} ta={"center"} c={"white"}>Flow Board</Title>
        </Card.Section>
        <Card.Section  onClick={()=>console.log("HELLO")}>
            <Text onClick={()=> handlers.toggle() } ta={"center"}>Filters</Text>
            {opened ? 
            <Flex my={""} gap="xl" justify="center" align="center">
            <Chip.Group value={filters} onChange={setFilters}>
                <Chip value="chrono">Suivi</Chip>
                <Chip value="run">Course</Chip>
                <Chip value="note">Notes</Chip>
            </Chip.Group>
            </Flex>
        :<></>}
        </Card.Section>
        <Divider/>
        <Card.Section>
        {flowQuery.status === "success" ? 
        <>
        <SimpleGrid cols={3}>
        {flowQuery?.data?.map((card)=> <AdaptiveCard data={card}/>)}
        </SimpleGrid>
        </>
        :<Skeleton></Skeleton>}
        </Card.Section>
    </Card>
    )
}

function AdaptiveCard({data}){
    return (
        <Card m={"lg"}component={Link} to={"/auth/"+data.type+"/"+data._id}>
            <Card.Section bg={"green"}>
                <Title mx={"md"} order={3} c={"white"}> {data?.titre}</Title>
            </Card.Section>
            <Card.Section>
                <Text truncate>
                {parse(data?.description)}
                </Text>
            </Card.Section>
        </Card>
    )   
}
import React from 'react';
import {Card,Text,Stack,Group} from "@mantine/core";
import { fetchWorkflowByWorkerID } from '../connections/requests';
import { useQuery } from 'react-query';

export default function WorkerWorkFlow({workerID}) {
  const jobQuery = useQuery(["workflow",workerID],fetchWorkflowByWorkerID);
  if(jobQuery.status==="success")
  return (
    <Card>
      <Card.Section bg={"blue"} p={"xs"}> 
        <Text color={"white"}>Plan de Travail</Text>
      </Card.Section>
      <Group>
      <Stack m={"sm"}>
        <Text>Journée Maladie Restant: {}</Text>
        <Text>Heure en banque : {}</Text>
        <Text>Heures comptabilisées : {jobQuery.data}</Text>
      </Stack>
      <Stack>
        <Text></Text>
      </Stack>
      </Group>
    </Card>
  )
  return("WAITING")
}

import { Stack,Text, TextInput,Flex, ActionIcon, Paper, SimpleGrid } from '@mantine/core'
import { TimeRangeInput } from '@mantine/dates';
import { IconMinus, IconPlus } from '@tabler/icons-react'
import dayjs from 'dayjs';
import { useState,useEffect} from 'react'

export default function TimeSlot(props) {
    const now = new Date();
    const then = dayjs(now).add(30,"millisecond").toDate();
    const [locInput, setLocInput] = useState("");
    const [locations,setLocations] = useState([]);
    const [timeInput,setTimeInput] = useState([now,then])
    const [times,setTimes] = useState([])
    console.log(timeInput)
    return (
        <>
            <Text >Lieux</Text>
            <SimpleGrid cols={6}>
            {times.map((loc,index)=> <TimeRow time={loc} index={index} key={loc}/>)}
                <Flex m={"xs"}>
                    <TimeRangeInput label="Temps" value={timeInput} onChange={setTimeInput} clearable/>
                    <ActionIcon component={IconPlus} onClick={()=> setTimes([...times,timeInput])}/>
                </Flex>
            </SimpleGrid>
        </>
    )
    function TimeRow({time,index}){
        console.log(time)
        return <div>
                        <Flex direction={"row-reverse"} >
                        <ActionIcon component={IconMinus} onClick={()=> setTimes(times.slice(0,index).concat(times.slice(index+1)))}/>
                        <TimeRangeInput label="Appointment time" value={time} onChange={setTimes} clearable />
                        </Flex>
                </div>
    }
}




import { Flex, Stack, Text, TextInput,Title, Button, Radio, Select, Textarea,Group, Checkbox,Paper } from '@mantine/core'
import { DatePicker } from '@mantine/dates';
import 'dayjs/locale/fr-ca';
import { useForm } from '@mantine/form'
import React from 'react'
import { patchMember } from '../connections/requests';

export default function MemberInfoEdit(props) {
    let { prenom, nom, dateOfBirth,dateOfRegistration,dateOfMembership } = props.member
    const form = useForm({ initialValues: { ...props.member, dateOfBirth: new Date(dateOfBirth),dateOfRegistration : new Date(dateOfRegistration),dateOfMembership : new Date(dateOfMembership)  } })
    return (
        <form>
            <Text size={"lg"} c={"dimmed"} align='center'>Modifier les infos pour l'usage {`${prenom} ${nom}`}</Text>
            <Stack>
            
                <Flex gap={"md"}>
                    <TextInput label="Prénom"  {...form.getInputProps('prenom')} />
                    <TextInput label="Nom" {...form.getInputProps('nom')} />
                </Flex>
                <TextInput label="Numéro de membre" {...form.getInputProps('idMembre')}/>
                <Radio.Group name="genre" {...form.getInputProps('genre')} label="Genre">
                    <Radio value="homme" label="Homme" />
                    <Radio value="femme" label="Femme" />
                    {// <Radio value="nonbinaire" label="Non-Binaire / Autre" />
                    }
                </Radio.Group>
                
                <TextInput label="Adresse Civique"  {...form.getInputProps('civic')} />
                <TextInput label="Ville" {...form.getInputProps("city")}/>
                <TextInput label="Code Postal"   {...form.getInputProps('postal')} />
                <TextInput label="Adresse Courriel" {...form.getInputProps('email')}/>
                <TextInput label="Téléphone"  {...form.getInputProps('phone')} />
                <DatePicker
                    inputFormat='DD MMMM YYYY'
                    labelFormat='DD/MMMM/YYYY'
                    locale='fr-ca'
                    style={{ marginTop: 20 }}
                    label="Date de Naissance"
                    allowFreeInput
                    clearable={false}
                    {...form.getInputProps('dateOfBirth')}
                />
                <DatePicker
                    inputFormat='DD MMMM YYYY'
                    labelFormat='DD/MMMM/YYYY'
                    locale='fr-ca'
                    style={{ marginTop: 20 }}
                    label="Date d'adhésion"
                    allowFreeInput
                    clearable={false}
                    {...form.getInputProps('dateOfRegistration')}
                />
                                <DatePicker
                    inputFormat='DD MMMM YYYY'
                    labelFormat='DD/MMMM/YYYY'
                    locale='fr-ca'
                    style={{ marginTop: 20 }}
                    label="Date du dernier renouvellement"
                    allowFreeInput
                    clearable={false}
                    {...form.getInputProps('dateOfMembership')}
                />
                <Paper withBorder bg={"gray"} p={"md"}>
                <Group>
                    <Title order={4}>Condition Physique</Title>
                    <Checkbox label={"Mobilité Réduite"} {...form.getInputProps("mobility")}/>

                        <Checkbox label={"Chaise Roulante"}/>

                    <Checkbox label={"Besoin de co-voiturage"}/>
                    <Checkbox label={"Offre de co-voiturage"}/>
                </Group>
                </Paper>

                <Paper withBorder bg={"gray"} p="md">
                <Group>
                    <Title order={4}>Aide Auditive</Title>
                    <Checkbox  label={"Système MF"} {...form.getInputProps("mf")}/>
                    <Checkbox  label={"ATS"} {...form.getInputProps("ats")}/>
                    {form.values.ats === false ? null:<TextInput/>}
                </Group>
                </Paper>
                <Paper> 
                    <Group bg={"gray"}>
                        <Title order={4}>Passe-Temps / Champs d'interets </Title>
                        <TextInput w={100}/>
                    </Group>
                </Paper>
                <Select
                    style={{ marginTop: 20, zIndex: 2 }}
                    data={['Régulier', 'Sympathisant', 'Non-Membre', 'Autres']}
                    placeholder={"Status membre"}
                    label="Status membre"
                    {...form.getInputProps('type')}
                />
                <Textarea label="Ajouter des notes" {...form.getInputProps('note')}/>
            </Stack>
            <Button onClick={() => patchMember(form)}>Soumettre</Button>
        </form>

    )
}

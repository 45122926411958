import { RichTextEditor, Link } from '@mantine/tiptap';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { Button,  Select, TextInput, Group,  ActionIcon, Modal, Text, Grid, Card, Title, Tooltip, Divider,  Overlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import {  IconSearch, IconX } from '@tabler/icons-react';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { queryClient } from '../App';
import { createOneChrono, searchMember } from '../connections/requests';

const content =
    '';

const { types } = {
    types:
        [
            { value: "phone", label: 'Téléphone' },
            { value: "visite", label: 'Présentiel' },
            { value: "mail", label: 'Courriel' }
        ]
};

export function Chrono(props) {
    const authWorker = useQueryClient();
    const m = props.modal;
    let memberId =""
    if(props.memberId) memberId=props.memberId;

    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            SubScript,
            Highlight,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
        content,
    });

    const form = useForm({ initialValues: { titre: "", description: "", worker: "", member: memberId, memberInfo: { prenom: "", nom: "" }, sudomember: { nom: "", phone: "", mail: "" }, type: "", status: "open" }, validate: { titre: (value) => (value.length > 2 ? null : "Donner un titre") } })
    const [search, setSearch] = useState("");
    const [debouncedSearch] = useDebouncedValue(search, 200)
    const { data, status } = useQuery(['members', debouncedSearch], searchMember);
    const [menuOpened, handlers] = useDisclosure(true);
    const chronoMutation = useMutation({ mutationFn: createOneChrono, onSettled: () => { console.log("SUCCESS"); queryClient.invalidateQueries({ queryKey: ['chrono'] });m(false) } });
    const [opened, setOpened] = useState(false);
    return (
        <div>
            <form>
                <Modal opened={opened} onClose={() => setOpened(false)} title="Recherche Membre">
                    <TextInput
                        label="Inscrire le nom ou prénom"
                        value={search}
                        style={{ flex: 1 }}
                        onChange={(event) => setSearch(event.currentTarget.value)}
                    />
                    {(status === "success") ? data.map(i => <Button color="dark" m={"xl"} key={i._id} onClick={(e) => { form.setValues({ member: i._id, memberInfo: { prenom: i.prenom, nom: i.nom } }); setOpened(false) }}><Text>{`${i.prenom} ${i.nom}`}</Text></Button>) : <Text my={"sm"} ta={"center"}>vide </Text>}
                </Modal>
                <></>
                {menuOpened ? <>
                <Group py="md" grow>
                    <Card h={266} withBorder shadow='md'>
                        <Card.Section withBorder bg={"blue"}>
                            <Title color='white' align='center' order={3}>Information</Title>
                        </Card.Section>
                        <TextInput my={"lg"} withAsterisk label="Titre" {...form.getInputProps('titre')} />
                        <Select my={"lg"} withAsterisk label='Type de chrono' data={types} {...form.getInputProps('type')} />
                    </Card>
                    <Card h={266} withBorder shadow='md'>
                        <Card.Section bg={"blue"} mb={'md'} withBorder><Title color='white' order={3} align='center'>Membre</Title></Card.Section>
                        <Grid >
                            <Grid.Col span={10}>
                                <TextInput variant='filled' onClick={() => setOpened(true)} disabled placeholder='Choisir un membre' {...form.getInputProps('member')} />
                            </Grid.Col>
                            <Grid.Col p={0} pt={10} span={1}>
                                <Tooltip label="Choisir un membre">
                                    <ActionIcon onClick={() => setOpened(true)} ><IconSearch size="1.125rem" /></ActionIcon>
                                </Tooltip>
                            </Grid.Col>
                            <Grid.Col p={0} pt={10} span={1}>
                                <Tooltip label="Retirer le membre">
                                    <ActionIcon onClick={() => { form.setValues({ member: "", memberInfo: { prenom: "", nom: "" } }) }}><IconX color='red' /></ActionIcon>
                                </Tooltip>
                            </Grid.Col>
                        </Grid>
                        <Divider my={"md"} />
                        {form.values.member !== "" ? <Text weight={500} ta={"center"} placeholder='Prénom' label="Prenom" >{`${form.values.memberInfo.prenom} ${form.values.memberInfo.nom}`}</Text> : <Text my={"xs"} ta={'center'}>Aucun membre selectioné</Text>}
                    </Card>
                    <Card h={266} py={"md"} m={"xs"} withBorder shadow='md'>
                        <Card.Section withBorder bg={"blue"}>
                            <Title color='white' align='center' order={3}>Non Membre</Title>
                        </Card.Section>
                        <Card.Section pb={"lg"} m={"xs"}>
                            {form.values.member !== "" && <Overlay opacity={0.6} color='black' zIndex={5} />}
                            <TextInput label="Nom" {...form.getInputProps('sudomember.nom')} />
                            <TextInput label="Telephone" {...form.getInputProps('sudomember.phone')} />
                            <TextInput label="Email" {...form.getInputProps('sudomember.mail')} />
                        </Card.Section>
                    </Card>
                </Group>
                <TextEditor editor={editor} form={form} />
                <Group p={"lg"} position='center'>
                    <Button onClick={() => {chronoMutation.mutate({ f: form, c: editor.getHTML(),worker:() =>authWorker.getQueryData("auth",{exact:false,inactive:false}) });form.reset();editor.commands.clearContent()}} color="violet">Enregistrer</Button>
                </Group></>
                : <></>
}
            </form>
        </div >
    );
}

function TextEditor({editor,form}){

    return<RichTextEditor editor={editor}  >
    <RichTextEditor.Toolbar sticky stickyOffset={60}>
        <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
            <RichTextEditor.Code />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Subscript />
            <RichTextEditor.Superscript />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>
    </RichTextEditor.Toolbar>
    <RichTextEditor.Content {...form.getInputProps('description')} />
</RichTextEditor>
}
import React from 'react'
import { ChronoBoard } from '../chrono/chronoBoard';
import { Chrono } from '../member/chrono';
import { Container, Tabs, Title } from '@mantine/core';
import {Route,Routes} from "react-router-dom";
import  ChronoPage  from "../chrono/Chrono";
import ChronoEdit from '../chrono/ChronoEdit';




export default function SuiviPage() {
    return (
    <>
        <Title order={1} ta={"center"} m={"xl"}></Title>
        <Routes>
            <Route exact path="" element={<MainPage />} />
            <Route path=":suivi" element={<IndividualPage />} />
        </Routes>
    </>
    )
}
function MainPage(){
    return(
        <Container size="xl" >
        <Chrono />
            <Tabs defaultValue={"all"}>
                <Tabs.List grow>
                    <Tabs.Tab value='all'>
                        Tous les suivis
                    </Tabs.Tab>
                    <Tabs.Tab value="active">
                        Suivi en attente
                    </Tabs.Tab>
                    <Tabs.Tab value='memberless'>
                        Suivi sans membre
                    </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value='all'>
                <ChronoBoard range={"all"} />
                </Tabs.Panel>
                <Tabs.Panel value='active'>
                <ChronoBoard range={"active"} />
                </Tabs.Panel>
                <Tabs.Panel value="memberless">
                <ChronoBoard range={"memberless"} />
                </Tabs.Panel>
            </Tabs>

        </Container>
        )
}
function IndividualPage(){
    return(
    <Container size="xl">
        <Tabs defaultValue={"chrono"}>
            <Tabs.List>
                <Tabs.Tab value='chrono'>
                    Suivi
                </Tabs.Tab>
                <Tabs.Tab value="edit">
                    Modifier
                </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value='chrono'>
                <ChronoPage/>
            </Tabs.Panel>
            <Tabs.Panel value='edit'>
                <ChronoEdit/>
            </Tabs.Panel>
        </Tabs>

    </Container>
    )
}
function NonMemberPage(){
    return(
    <Container>
        <ChronoBoard range={"memberless"}/>
    </Container>
    )
}
import React from 'react'
import { MemberTable } from '../member/memberlist'
import { useQuery } from 'react-query';
import { Card, Container, Skeleton, Title,  useMantineTheme, Flex,UnstyledButton,Text,Stack } from '@mantine/core';
import { Link } from 'react-router-dom';
import { IconNotebook, IconUserPlus } from '@tabler/icons-react';
import { exportAllMembers, fetchAllMembers } from '../connections/requests';
import {Routes,Route} from "react-router-dom";
import Member from '../member/member';


export default function MemberPage() {
    return (
        <Routes>
            <Route path='' element={<MainPage/>}/>
            <Route path=":membre" element={<Member />} />
        </Routes>
    )
}
function MainPage(){
    const { data,status } = useQuery("members", fetchAllMembers);
    const theme = useMantineTheme();
    if(status === "success") return(
            <Container py={"lg"}>
            <Card withBorder>
                <Card.Section p={"md"} bg={theme.colors.blue}>
                    <Title color='white' ta={"center"}>Liste des membres</Title>
                </Card.Section>
                <Card.Section mx={"lg"} my={"md"}>
                <Flex gap={'md'}>
            <UnstyledButton bg={"white"} component={Link} to="/auth/form/addMember">
                <Stack align='center' justify='center'>
                <IconUserPlus color='green' size={32} />
                <Text size="xs" color="green">
                    Ajouter membre
                </Text>
                </Stack>
            </UnstyledButton>
            <UnstyledButton onClick={()=> exportAllMembers()} bg={"white"}>
                <Stack align='center' justify='center'>
                    <IconNotebook color='blue' size={32}/>
                    <Text size={"xs"} mt={7} color='blue'>
                        Exporter la liste des membres
                    </Text>
                </Stack>
            </UnstyledButton>
            </Flex>
                </Card.Section>
                <Card.Section p={"lg"}>
                    <MemberTable data={data} />
                </Card.Section>
                </Card>
            </Container>)
    return <Skeleton/>
}
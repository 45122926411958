import { Card, Grid, Group, Stack, Text, Title } from '@mantine/core'
import React from 'react'

export default function GrantInfo({grant}) {
    return (
        <Card>
            <Card.Section>
                <Title order={3}>{`Projet : ${grant?.title}`}</Title>
            </Card.Section>
            <Card.Section>
                <Grid>
                    <Grid.Col span={5}>
                    <Stack m={"lg"}>
                        <Text> {`Fournisseur : ${grant?.provider}`}</Text>
                        <Text>{`Montant : ${grant?.amount}`}</Text>
                        <Text>{`Date finale : ${new Date(grant?.deadline).toLocaleString("fr-FR",{
  day: 'numeric',
  month: 'long', 
  year: 'numeric'
})}`}</Text>
<Text>- {Math.floor((new Date(grant?.deadline).getTime()- new Date().getTime())/(1000*60*60*24))} jours avant la date finale</Text>
                    </Stack>
                    </Grid.Col>
                    <Grid.Col span={5}>
                    <Stack m={"lg"}>
                        <Text>Description</Text>
                        <Text>{grant?.description}</Text>
                    </Stack>
                    </Grid.Col>
                </Grid>
            </Card.Section>
        </Card>
    )
}

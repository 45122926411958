import { createStyles, Card, Container, Grid, Title } from '@mantine/core';
import CanvasJSReact from '@canvasjs/react-charts';
import { useQuery } from 'react-query';
import { getDemography,getAge,getChronotivity,getGender } from '../connections/requests';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const useStyles = createStyles((theme) => ({
    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 700,
    },

    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: theme.radius.md,
        height: 90,
        transition: 'box-shadow 150ms ease, transform 100ms ease',

        '&:hover': {
            boxShadow: `${theme.shadows.md} !important`,
            transform: 'scale(1.05)',
        },
    },
}));

function getOptions(data, title,type) {
    return {
        height:230,
        title: {
            text: title
        },
        data: [{
            type: type,
            dataPoints: data
        }]
    }

}
function getTimelineOptions() {
    return {
        title: { text: "Ligne du temps des suivis" },
        data: [{
            type: "line",
            name: "Total",
            showInLegend: true,
            dataPoints: [
                { x: 1, y: 12 },
                { x: 2, y: 15 },
                { x: 3, y: 17 },
                { x: 4, y: 18 },
                { x: 5, y: 18 },
                { x: 6, y: 18 },
                { x: 7, y: 21 },
                { x: 8, y: 25 },
                { x: 9, y: 25 },
                { x: 10, y: 28 },
                { x: 11, y: 29 },
                { x: 12, y: 35 },

            ]
        },
        {
            type: "line",
            name: "Terminé",
            showInLegend: true,
            dataPoints: [
                { x: 1, y: 1 },
                { x: 2, y: 2 },
                { x: 3, y: 4 },
                { x: 4, y: 4 },
                { x: 5, y: 5 },
                { x: 6, y: 6 },
                { x: 7, y: 4 },
                { x: 8, y: 2 },
                { x: 9, y: 4 },
                { x: 10, y: 1 },
                { x: 11, y: 2 },
                { x: 12, y: 1 },

            ]
        }
        ]
    }
}

export function Stats() {
    const { classes } = useStyles();
    const demographyQuery = useQuery('demography', getDemography)
    const chronotivityQuery = useQuery('chronotivity', getChronotivity)
    const genderQuery = useQuery("gender",getGender);
    const ageQuery = useQuery("age",getAge);



    return (
        <Container size={"md"} py={"lg"} >
            <Title order={1}  ta={"center"} my={"lg"} className={classes.title}>Rapports</Title>
            <Grid>
                <Grid.Col span={4}>
                    <Card withBorder radius="md" className={classes.card}>
                        {demographyQuery.status === "success" && <CanvasJSChart options={getOptions(demographyQuery.data.activityData, demographyQuery.data.metaData.titleactivity,"pie")} />}
                    </Card>
                </Grid.Col>
                <Grid.Col span={4}>
                    <Card withBorder radius="md" className={classes.card}>
                        {demographyQuery.status === "success" && <CanvasJSChart options={getOptions(demographyQuery.data.typeData, demographyQuery.data.metaData.titletype,"pie")} />}
                    </Card>
                </Grid.Col>
                <Grid.Col span={4}>
                    <Card withBorder radius="md" className={classes.card}>
                        {chronotivityQuery.status === "success" && <CanvasJSChart options={getOptions(chronotivityQuery.data, "Répartition des suivis","pie")} />}
                    </Card>
                </Grid.Col>  
                <Grid.Col span={4}>
                    <Card withBorder radius="md" className={classes.card}>
                    {genderQuery.status === "success" && <CanvasJSChart options={getOptions(genderQuery.data.genderData,"Répartition des genres","pie")}/>}
                    </Card>
                </Grid.Col>
                <Grid.Col span={8}>
                    <Card withBorder radius="md" className={classes.card}>
                    {ageQuery.status === "success" && <CanvasJSChart options={getOptions(ageQuery.data.ageData,"Répartition des ages","column")}/>}
                    </Card>
                </Grid.Col>
            </Grid>
            <Card m={"xl"}><CanvasJSChart options={getTimelineOptions()} /></Card>
        </Container>
    );
}
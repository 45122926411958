import React from 'react'

import { UnstyledButton,Menu,createStyles, Avatar,Stack } from '@mantine/core';
import { IconSettings,IconLogout } from '@tabler/icons';
import {useQuery} from "react-query";
import { Link } from 'react-router-dom';

import { fetchWorker, getUser } from '../connections/requests';

const useStyles = createStyles((theme) => ({
    placeholder:{
        borderWidth:0,
        color:"red",
    },

    header: {
        paddingTop: theme.spacing.sm,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.blue[9],
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? 'transparent' : theme.colors.blue[9]
            }`,

    },

    mainSection: {
        paddingBottom: theme.spacing.sm,
    },

    user: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        border:0,
        borderWidth:0,
        transition: 'background-color 100ms ease',

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        },
        
    },

    burger: {
        [theme.fn.largerThan('xs')]: {
            display: 'none',
            border:0,
        },
    },

    userActive: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        border:0,
    },

    tabs: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    tabsList: {
        borderBottom: '0 !important',
    },

    tab: {
        fontWeight: 500,
        height: 38,
        backgroundColor: 'transparent',

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
        },

        '&[data-active]': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
        },
    },
}));


export default function HeadBadge() {
    const { classes, cx } = useStyles();
    const { data, status } = useQuery(['auth', getUser()], fetchWorker);
  return (
    <>       
    <Menu
    width={260}
    position="bottom-end"
    transition="pop-top-right"

>
    <Menu.Target>
        <Stack>
         <Avatar component={UnstyledButton} className={cx(classes.user)} radius={'xl'}>{status==="success"&& data?.initials ? data?.initials : null}</Avatar>
        </Stack>
    </Menu.Target>
    <Menu.Dropdown>
 
        <Menu.Label>Paramètres</Menu.Label>
        <Menu.Item component={Link} to={"/auth/user"} icon={<IconSettings size={14} stroke={1.5} />}>Paramètres</Menu.Item>
        <Menu.Item icon={<IconLogout size={14} stroke={1.5} />}>Déconnexion</Menu.Item>
    </Menu.Dropdown>
</Menu>
</>
  )
}

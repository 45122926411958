import React,{useState} from 'react'
import { Container, Title,Select, Paper, Flex, UnstyledButton,Stack, Card } from '@mantine/core';
import { ChronoCard } from '../member/chronoCardModal';
import {Route,Routes,Link} from "react-router-dom";
import { Tabs,Text } from '@mantine/core';
import { useQuery } from 'react-query';
import { fetchPaymentsFromMonth,fetchAllPayments, fetchPaymentsBetween, fetchAllRuns, fetchRunsFromMonth } from '../connections/requests';
import {exportAllPayments} from "../connections/requests";
import { IconPencilPlus } from '@tabler/icons-react';
import { PaymentTableBetween } from '../payment/PaymentTableBetween';
import { DatePicker } from '@mantine/dates';
import { RunTable } from '../run/RunTable';
import {RunTableAll} from "../run/RunTableAll"


const months= [ 
{ value:'0',label:"Janvier"},
{ value: '1', label: 'Février' },
{ value: '2', label: 'Mars' },
{ value: '3', label: 'Avril' },
{ value: '4', label: 'Mai' },
{ value: '5', label: 'Juin' },
{ value: '6', label: 'Juillet' },
{ value: '7', label: 'Août' },
{ value: '8', label: 'Septembre' },
{ value: '9', label: 'Octobre' },
{ value: '10', label: 'Novembre' },
{ value: '11', label: 'Décembre' }
];

export default function RunPage() {
    return (
        <Container size={"lg"}>
            <Card my={"lg"}>
                <Card.Section my={"lg"} bg={"blue"}>
                    <Title color='white' order={1} ta={"center"}>Déplacement</Title>
                </Card.Section>
        <Flex gap={'md'}>
            <UnstyledButton bg={"white"} component={Link} to="/auth/form/addRun">
                <Stack align='center' justify='center'>
                <IconPencilPlus color='green' size={32} />
                <Text size="xs" color="green">
                    Ajouter une Déplacement
                </Text>
                </Stack>
            </UnstyledButton>
            </Flex>
        <Routes>
            <Route exact path="" element={<MainPage />} />
            <Route path=":run" element={<ChronoCard />} />
        </Routes>
        </Card>
        </Container>
    )
}
function MainPage(){
    return(
        <Container size="xl" >
        <Tabs defaultValue="Courses mensuelles" >
            <Tabs.List grow >
                <Tabs.Tab value="Liste"><Text size={"xl"}>Liste</Text></Tabs.Tab>
                <Tabs.Tab value="Courses mensuelles"><Text size={"xl"}>Déplacements mensuelles</Text></Tabs.Tab>
                
            </Tabs.List>

            <Tabs.Panel value="Liste" pt="xs">
                <AllPage/>
            </Tabs.Panel>

            <Tabs.Panel value="Courses mensuelles" pt="xs">
                <MonthlyPage/>
            </Tabs.Panel>

            <Tabs.Panel value="Courses spécifiques" pt="xs">
                <BetweenPage/>
            </Tabs.Panel>
        </Tabs>
        </Container>
        )
}
function AllPage(){
    const {data,status} = useQuery('run',fetchAllRuns);
    if(status==='success')return(        
    <Container>
        <RunTableAll data={data}/>
    </Container>)
    return <div>null</div>
}
function MonthlyPage(){
    let [monthID,setMonthID] = useState(new Date().getMonth().toString());
    const {data,status} = useQuery(['run',monthID],fetchRunsFromMonth);
    if (status==='success')return(
        <Container>
            <Paper p={'lg'}>
            <Select label="Mois" defaultValue={monthID} data={months} value={monthID} onChange={setMonthID} />
        { <RunTable updater={(e)=> {e++}} month={monthID} data={data}/> }
            </Paper>
        </Container>
    );
    return <div>null</div>
}
function BetweenPage(){
    const [startingDate, setStarting] = useState(
        new Date(),
    );
    const [endingDate, setEnding] = useState(
        new Date(),
    );
    const {data,status} = useQuery(['run',[startingDate,endingDate]],fetchPaymentsBetween);
    if (status==='success')return(
        <Container>
            <Paper p={'lg'}>
            <DatePicker
    label="Dates"
    placeholder="Choisir des dates"
    value={startingDate}
    onChange={setStarting}
    />
    <DatePicker
        label="Dates"
        placeholder="Choisir des dates"
        value={endingDate}
        onChange={setEnding}
    />
        <PaymentTableBetween  data={data}/>
            </Paper>
        </Container>
    );
    return <div>null</div>

    
}
import { createStyles, Group, Paper, Text, ThemeIcon, SimpleGrid, Grid } from '@mantine/core';
import { IconArrowUpRight, IconArrowDownRight } from '@tabler/icons-react';
import { useQuery } from 'react-query';
import {  fetchAlerts,fetchToDo, fetchAllStats } from '../connections/requests';
import DashCard from '../dashboard/DashCard';
import ErrorCard from '../dashboard/ErrorCard';
import FlowBoard from '../dashboard/FlowBoard';

const useStyles = createStyles((theme) => ({
    root: {
        padding: theme.spacing.xl * 1.5,
    },

    label: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
}));

export default function MainPage() {
    const { classes } = useStyles();
    const { data, status } = useQuery('dashboard', fetchAllStats);
    const butler = useQuery("butler",fetchToDo);
    let stats = [];
    if (status === "success")
        stats =
            data.map((stat) =>
                <Paper withBorder p="md" radius="md" key={stat.label}>
                    <Group position="apart">
                        <div>
                            <Text
                                color="dimmed"
                                transform="uppercase"
                                weight={700}
                                size="xs"
                                className={classes.label}
                            >
                                {stat.label}
                            </Text>
                            <Text weight={700} size="xl">
                                {stat.value}
                            </Text>
                        </div>
                        <ThemeIcon
                            color="gray"
                            variant="light"
                            sx={(theme) => ({ color: stat.diff > 0 ? theme.colors.teal[6] : theme.colors.red[6] })}
                            size={38}
                            radius="md"
                        >
                            {
                                stat.diff ?
                                    (stat.diff > 0 ? <IconArrowUpRight size={28} stroke={1.5} /> : <IconArrowDownRight size={28} stroke={1.5} />) :
                                    <div></div>
                            }
                        </ThemeIcon>
                    </Group>
                    {stat.diff !== 0 &&
                        <Text color="dimmed" size="sm" mt="md">
                            <Text component="span" color={stat.diff > 0 ? 'teal' : 'red'} weight={700}>
                                {stat.diff}%
                            </Text>{' '}
                            {stat.diff > 0 ? 'increase' : 'decrease'} compared to last month
                        </Text>
                    }
                </Paper>)
    else stats = <Text> "Loading" </Text>



    return (
        <div className={classes.root}>
            <Grid my={"xl"} cols={12} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                <Grid.Col span={4}>
                <Paper withBorder p="md" radius="md">
                <Text
                                color="dimmed"
                                transform="uppercase"
                                weight={700}
                                size="xs"
                                className={classes.label}
                            >
                                Alertes
                            </Text>
                    <div >
                        <Text>Aucune Alerte</Text>
                    </div>
                </Paper>
                </Grid.Col>
                <Grid.Col span={8}>
                <Paper withBorder p="md" radius="md">
                <Text
                                color="dimmed"
                                transform="uppercase"
                                weight={700}
                                size="xs"
                                className={classes.label}
                            >
                                En Attente
                            </Text>
                    <div >
                        {butler.status === "success" ? butler.data.slice(0,3).map(data => <DashCard card={data} cardType={"chrono"} />):<Text>Tout va bien</Text>}
                        
                    </div>
                </Paper>
                </Grid.Col>
            </Grid>
            <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                {stats}
            </SimpleGrid>
            <FlowBoard/>
        </div>
    );
};
import { TextInput, Textarea,  Button, Text,  ActionIcon, Card, Grid, Stack, NumberInput, Tooltip, Title, Divider, Modal, Flex, Table, UnstyledButton, Select,  Paper } from '@mantine/core';
import { useState } from "react"
import { DatePicker } from '@mantine/dates';
import {  IconSearch, IconX } from '@tabler/icons';
import 'dayjs/locale/fr-ca';
import { useForm } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import {  useQuery } from 'react-query';
import { createOneRun, fetchAllMembers, fetchAllWorkers } from '../connections/requests';
import LocationBoard from './components/LocationBoard';


export function AddRunForm() {
    const [openedWorker, setOpenedWorker] = useState(false);
    const [openedMember, setOpenedMember] = useState(false);
    const [workerName,setWorkerName] = useState("");
    const [memberName,setMemberName] = useState("");
    const [search, setSearch] = useState("");
    const [debouncedSearch] = useDebouncedValue(search, 200)
    const [searchW, setSearchW] = useState("");
    const [debouncedSearchW] = useDebouncedValue(searchW, 200)
    const [value, setValue] = useState();
    const workerQuery = useQuery(['workers', debouncedSearchW], fetchAllWorkers);
    const memberQuery = useQuery(['members', debouncedSearch], fetchAllMembers);

    const form = useForm({
        initialValues: { worker: undefined, member: undefined, locations: [], distance: 0, hour: 0, minute: 0, description: "",type:"",timeStamp: new Date() }
    })
    return (
        <>
            <Modal opened={openedWorker} onClose={() => setOpenedWorker(false)} title="Recherche Membre">
                <TextInput
                    label="Inscrire le nom ou prénom"
                    value={searchW}
                    style={{ flex: 1 }}
                    onChange={(event) => setSearchW(event.currentTarget.value)}
                />
                {(workerQuery.status === "success" && workerQuery.data.length > 0) ? workerQuery.data.filter(e => (new RegExp(debouncedSearchW,'i').test(e.prenom)|| new RegExp(debouncedSearchW,'i').test(e.nom)) ).map(i => <Button color="dark" m={"xl"} key={i._id} onClick={(e) => { form.setValues({ worker: i._id }); setOpenedWorker(false);setWorkerName(`${i.prenom} ${i.nom}`) }}><Text>{`${i.prenom} ${i.nom}`}</Text></Button>) : <Text my={"sm"} ta={"center"}>vide </Text>}
            </Modal>
            <Modal opened={openedMember} onClose={() => setOpenedMember(false)} title="Recherche Membre">
                    <TextInput
                        label="Inscrire le nom ou prénom"
                        value={search}
                        style={{ flex: 1 }}
                        onChange={(event) => setSearch(event.currentTarget.value)}
                    />
                    {(memberQuery.status === "success") ? 
                    <Table>
                    <tbody>
                    {memberQuery.data.filter(e => (new RegExp(debouncedSearch,'i').test(e.prenom)|| new RegExp(debouncedSearch,'i').test(e.nom)) ).map(i => <tr color="dark" m={"xl"} key={i._id} ><td><UnstyledButton onClick={(e) => { form.setValues({ member: i._id}); setOpenedMember(false);setMemberName(`${i.prenom} ${i.nom}`) }}>{`${i.prenom} ${i.nom}`}</UnstyledButton></td></tr>) }
                    </tbody>
                    </Table>
                    : <Text my={"sm"} ta={"center"}>vide</Text>}
            </Modal>
            <Stack p={"xl"} m={'lg'}>
                <Flex gap={"lg"} justify={"center"} wrap={true} direction={{ base: 'column', sm: 'row' }}>
                <Paper p={"md"} withBorder shadow='md'>
                    <Stack>
                    <DatePicker label="Date" {...form.getInputProps("timeStamp")}/>
                    <Select label="Type" data={['Déplacement', 'Bénévolat']} value={value} onChange={setValue} {...form.getInputProps("type")} />
                    </Stack>
                </Paper>
                    <Card withBorder shadow='md'>
                        <Card.Section bg={"blue"} mb={'md'} withBorder><Title color='white' order={3} align='center'>Travailleur</Title></Card.Section>
                        <Grid >
                            <Grid.Col span={9}>
                                <TextInput variant='filled' onClick={() => setOpenedWorker(true)} disabled placeholder='Choisir un travailleur' {...form.getInputProps('worker')} />
                            </Grid.Col>
                            <Grid.Col p={0} pt={10} span={3}>
                                <Flex wrap={true} direction={{ base: 'column', sm: 'row' }}>
                                    <Tooltip label="Choisir un membre">
                                        <ActionIcon onClick={() => setOpenedWorker(true)} ><IconSearch size="1.125rem" /></ActionIcon>
                                    </Tooltip>
                                    <Tooltip label="Retirer le membre">
                                        <ActionIcon onClick={() => { form.setValues({ worker: "" }) }}><IconX color='red' /></ActionIcon>
                                    </Tooltip>
                                </Flex>
                            </Grid.Col>
                        </Grid>
                        <Divider my={"md"} />
                        {workerName !== "" ?
                            <Text weight={500} ta={"center"} placeholder='Prénom' label="Prenom" >
                                {workerName}
                            </Text> : <Text my={"xs"} ta={'center'}>Aucun travailleur sélectionné</Text>}
                    </Card>
                    <Card withBorder shadow='md'>
                        <Card.Section bg={"blue"} mb={'md'} withBorder><Title color='white' order={3} align='center'>Membre</Title></Card.Section>
                        <Grid >
                            <Grid.Col span={9}>
                                <TextInput variant='filled' onClick={() => setOpenedMember(true)} disabled placeholder='Choisir un membre' {...form.getInputProps('member')} />
                            </Grid.Col>
                            <Grid.Col p={0} pt={10} span={2}>
                                <Flex wrap={true} direction={{ base: 'column', sm: 'row' }}>
                                    <Tooltip label="Choisir un membre">
                                        <ActionIcon onClick={() => setOpenedMember(true)} ><IconSearch size="1.125rem" /></ActionIcon>
                                    </Tooltip>
                                    <Tooltip label="Retirer le membre">
                                        <ActionIcon onClick={() => { form.setValues({ member: "" }) }}><IconX color='red' /></ActionIcon>
                                    </Tooltip>
                                </Flex>
                            </Grid.Col>
                        </Grid>
                        <Divider my={"md"} />
                        {memberName !== "" ? <Text weight={500} ta={"center"} placeholder='Prénom' label="Prenom" >{memberName}</Text> : <Text my={"xs"} ta={'center'}>Aucun membre sélectionné</Text>}
                    </Card>
                </Flex>

                <Card>
                    <Card.Section bg={"blue"}><Title order={3} ta={"center"} c={"white"}>Déplacement</Title></Card.Section>
                <Grid>
                    <Grid.Col span={9}>
                        <LocationBoard {...form.getInputProps("locations")}/>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <Stack>
                        <NumberInput precision={2} step={0.25} defaultValue={0} formatter={(value) =>!Number.isNaN(parseFloat(value)) ? `${value} km`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','): 'kilomètre '}  label="Kilométrage" {...form.getInputProps('distance')}/>
                        </Stack>
                    </Grid.Col>
                </Grid>
                </Card>
                <Card>
                    <Card.Section bg={"blue"}><Title order={3} ta={"center"} c={"white"}>Bénévolat</Title></Card.Section>
                <Grid>
                    <Grid.Col span={9}>
                        <Textarea my={"xl"} label="Description" placeholder="" {...form.getInputProps('description')} />
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <Stack>
                        <NumberInput precision={2} step={0.25} defaultValue={0} formatter={(value) =>!Number.isNaN(parseFloat(value)) ? `${value} hr`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','): 'heures '}  label="Heures" {...form.getInputProps('hours')}/>
                        </Stack>
                    </Grid.Col>
                </Grid>
                </Card>

                <Button onClick={() => {createOneRun(form);form.reset()}}>Enregistrer</Button>
            </Stack >
        </>
    );
}
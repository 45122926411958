import React, { useState } from 'react';
import {Link} from "react-router-dom";
import { Menu, Text,ActionIcon, Modal, TextInput, Button, Stack } from '@mantine/core';
import { IconDots } from '@tabler/icons-react';
import { deletePayment,cancelPayment,receivePayment } from '../../connections/requests';
import RunStatusIcon from './RunStatusIcon';
import RunTypeIcon from './RunTypeIcon';
import { useDisclosure } from '@mantine/hooks';

export default function RunRow({row}) {
  const [opened,handlers] = useDisclosure(false)
  const [cancelNote,setCancelNote] = useState("");
  console.log("child",row)

  return (
    <>
    <Modal opened={opened} onClose={()=>handlers.close}>
      <Stack>
        <Text>Raison de l'annulation</Text>
        <TextInput value={cancelNote} onChange={(event) => setCancelNote(event.currentTarget.value)} />
        <Button my={"md"} color='red' onClick={()=>cancelPayment(row._id,cancelNote)}>Annuler</Button>
      </Stack>
    </Modal>
    <tr key={row?._id}>
    <td><Text ta={"right"} component={Link} to={ "/auth/team/"+row?.worker?._id} style={{ textDecoration: 'none', textAlign: "right" }}>{!row?.worker ? "aucun utilisateur" : row.worker.prenom + " " + row.worker.nom}</Text></td>
    {
    row?.member ? 
      <td><Text component={Link} to={ "/auth/member/"+row?.member?._id} style={{ textDecoration: 'none' }}>{!row.member? "aucun membre":row.member.prenom + " " + row.member.nom}</Text></td> :
      <td><Text>aucun membre</Text></td>  }
    <td>{row?.distance+" km"}</td>
    <td>{new Date(row?.timeStamp).toLocaleDateString('fr-FR')}</td>
    <td>{RunTypeIcon(row.type)}</td>
    <td><Menu shadow="md" width={200}>
      <Menu.Target>
        <ActionIcon>
            <IconDots size={22}/>
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={()=>deletePayment(row._id)}>Supprimer</Menu.Item>
        <Menu.Item onClick={()=>handlers.open()}>Annuler</Menu.Item>
        <Menu.Item onClick={()=>receivePayment(row._id)}>Reçu</Menu.Item>
      </Menu.Dropdown>
    </Menu></td>
</tr>
</>
  )
}



import React, { useState } from 'react'
import { Button, Container, Flex, Grid, Modal, Tabs } from '@mantine/core';
import { Chrono } from '../member/chrono';
import { useQuery } from 'react-query';
import {  useNavigate, useParams } from 'react-router-dom';
import { WorkerInfo } from './workerInfo';
import {deleteWorkerByID,fetchJobsByWorkerID,fetchWorker } from '../connections/requests';
import { TeamCard } from './components/TeamCard';
import WorkerInfoEdit from './WorkerInfoEdit';
import DayBoard from './DayBoard';
import WorkerWorkFlow from './WorkerWorkFlow';
import { WorkStatTable } from './WorkStatTable';
import VolunteerStats from './VolunteerStats';


export default function WorkerPage() {
    let redirect = useNavigate();
    let { worker } = useParams();
    const [openedChrono, setOpenedChrono] = useState(false);
    const [openedDelete, setOpenedDelete] = useState(false);
    const [openedEdit, setOpenedEdit] = useState(false);
    const { data, status } = useQuery(['workers', worker], fetchWorker);
    const jobsQuery = useQuery(['jobs',worker],fetchJobsByWorkerID);
    if (status === "success")
        return (
            <Container size={"lg"} my="md">
                <Flex my={"md"} gap={"md"} justify={"flex-start"}>
                    <Button color='red' onClick={() => setOpenedDelete(true)}>Supprimer l'utilisateur</Button>
                    <Button color='green' onClick={() => setOpenedEdit(true)}>Modifier l'utilisateur</Button>
                </Flex>
                <Modal
                    opened={openedChrono}
                    onClose={() => setOpenedChrono(false)}
                    size="75%"
                >
                    <Chrono memberId={worker} modal={setOpenedChrono} />
                </Modal>

                <Modal opened={openedDelete}
                    onClose={() => setOpenedDelete(false)}
                >
                    <Button onClick={()=> {deleteWorkerByID(worker);redirect("/auth/team")}}>Confirmer la suppression</Button>
                </Modal>
                <Modal opened={openedEdit}
                    onClose={()=>setOpenedEdit(false)}
                    >
                    <WorkerInfoEdit worker={data}/>
                    </Modal>
                <Grid gutter="md">
                    <Grid.Col span={6}>
                        <TeamCard props={data} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <WorkerInfo props={data} />
                    </Grid.Col>
                    <Tabs defaultValue={data.rank === "Benevole" ? "volunteer":"hours"}>
                        <Tabs.List>
                            <Tabs.Tab value='deplacement'>Déplacement</Tabs.Tab>
                            <Tabs.Tab disabled={data.rank === "Benevole"? true : false}  value='hours'>Heures</Tabs.Tab>
                            <Tabs.Tab value='volunteer'>Bénévolat</Tabs.Tab>
                        </Tabs.List>
                    <Tabs.Panel value='hours'>
                    <Grid.Col>
                        <WorkerWorkFlow workerID={worker}/>
                    </Grid.Col>
                    <Grid span={12}>
                        <DayBoard workerID={worker}/>
                    </Grid>
                    </Tabs.Panel>
                    <Tabs.Panel value='volunteer'>

                    <Grid span={12}>                    
                    <Grid.Col span={12}>
                        <VolunteerStats worker={worker}/>
                    </Grid.Col>
                        {jobsQuery.status==="success" ? <WorkStatTable data={jobsQuery.data}/> : null}
                    </Grid>
                    </Tabs.Panel>
                    </Tabs>
                </Grid>
            </Container>
        );
}

import { ActionIcon, Card,Flex,NumberInput,Stack,Text } from '@mantine/core'
import { TimeRangeInput } from '@mantine/dates';
import { useDebouncedState, useDebouncedValue, useForceUpdate } from '@mantine/hooks';
import { IconDeviceFloppy, IconMinus, IconPlus } from '@tabler/icons-react';
import { modifyJobDay } from '../connections/requests';
import React, { useState,useEffect } from 'react'
import { useQuery } from 'react-query';
//const jobQuery = useQuery("job",JobID,fetchJobByID);

export default function DayCard(props) {
    const {hours:stringhours,date,onChange,mode="card"} = props;
    const hour = convertToDateArray(stringhours);
    const [hours,setHours] = useState(hour);
    const [total, setTotal] = useState(0);
    const forceUpdate = useForceUpdate();
    useEffect(()=>{if(onChange)onChange(hours)},[hours])
    let editMode = false;
    if(mode==="component")
        return(
            <Card m={"xl"}>
            <Card.Section bg={"blue"} p={"xs"}>
            <Text c={"white"} ta={"center"}>{getDay(date)}</Text>
            </Card.Section>
            <Card.Section p={"md"}>
                Total d'heure : {total}
            </Card.Section>
            <Card.Section p={"lg"}>
                <Stack justify='center' align='center'>
                    {hours.map((e,index) => <Flex key={e}><HourInput e={e} index={index} handleChange={forceUpdate}/><ActionIcon onClick={()=> setHours(hours.filter(f => f!==e))}><IconMinus/></ActionIcon></Flex> )}
                    <ActionIcon onClick={()=>setHours([...hours,[new Date(),new Date(Date.now()+1000*60*60)]])}>
                        <IconPlus/>
                    </ActionIcon>
                </Stack>
            </Card.Section>
        </Card>)
    if(editMode)
        return (
            <Card>
            <Card.Section>
            <Text>{date.getDay().toString()}</Text>
            </Card.Section>
            <Card.Section>
                
            </Card.Section>
        </Card>
    )
    return (
        <Card m={"xl"}>
            <Card.Section bg={"blue"} p={"xs"}>
            <Text c={"white"} ta={"center"}>{getDay(date)}</Text>
            </Card.Section>
            <Card.Section p={"md"}>
                Total d'heure : {total}
            </Card.Section>
            <Card.Section p={"lg"}>
                <Stack justify='center' align='center'>
                    {hours.map((e,index) => <Flex key={e}><HourInput e={e} index={index} handleChange={forceUpdate}/><ActionIcon onClick={()=> setHours(hours.filter(f => f!==e))}><IconMinus/></ActionIcon></Flex> )}
                    <ActionIcon onClick={()=>setHours([...hours,[new Date(),new Date(Date.now()+1000*60*60)]])}>
                        <IconPlus/>
                    </ActionIcon>
                </Stack>
            </Card.Section>
            <Card.Section>
                <Flex direction={"row-reverse"}>
                    <ActionIcon onClick={()=>modifyJobDay(hours,props._id)} mt={"xs"} mx={"xs"}>
                        <IconDeviceFloppy/>
                    </ActionIcon>
                </Flex>
            </Card.Section>
        </Card>
    )
    function HourInput({e,index}){
        const [time,setTime] = useState(e);
        const [debouncedTime] = useDebouncedValue(time,1100)
        useEffect(()=>{
                hourFixer(index,debouncedTime)
            
        },[debouncedTime,index])
        return <TimeRangeInput value={time} onChange={setTime} on format='24'/>
    }

    function hourFixer(index,newHour){
        let response = hours;
        response[index] = newHour;
        if(response !== hours)
        setHours(response)
        totalHours()
    }
    function totalHours(){
        try {
            const response = hours.reduce(
                (accumulator , current ) =>  accumulator+ current[1].getHours()-current[0].getHours(),0
            )
            setTotal(response)
            return response
        } catch (error) {
            return 1
        }
        
        }   
}


function getDay(date){
    const index = new Date(date).getDay();
    const day = new Date(date).getDate();
    const month = new Date(date).getMonth();
    const m = ["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi"];
    const f = [
        "janvier", 
        "février", 
        "mars", 
        "avril", 
        "mai", 
        "juin", 
        "juillet", 
        "août", 
        "septembre", 
        "octobre", 
        "novembre", 
        "décembre"
    ];
    return `${m[index]} ${day.toString()} ${f[month]}`

}
function convertToDateArray(arr) {
    return arr.map(subArray => subArray.map(dateStr => new Date(dateStr)));
}
